.no-documents-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.no-documents-container>img {
    margin-top: 180px;
}

.no-documents-title {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #242424;
}

.no-documents-text {
    margin-top: 8px;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #7C7C7C;
}
.upload-reports-container{
    display: flex;
    flex-direction: column;
    margin-left: 2px;
    margin-top: 20px;
}
.upload-docs-btn{
    margin-top: 84px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 119px;
    height: 42px;
    background: #E94D51;
    border-radius: 20px;
    border: none;
    margin-bottom: 100px;
}
.upload-docs-btn span {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #FFFFFF;
}
.no-documents-btn {
    margin-top: 84px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 291px;
    height: 44px;
    background: #E94D51;
    border-radius: 10px;
    border: none;
}

.no-documents-btn img {
    height: 16px;
    width: 16px;
    margin-right: 9px;
}

.no-documents-btn span {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #FFFFFF;
}

.upload-reports-container span{
    margin-left: 10px !important;
}
.upload-docs-container{
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.upload-report-docs {  
    margin: 6px 10px;
    width: fit-content;
    padding: 8px;
    display: flex;
    align-items: center;
    background: #FAFAFA;
    border-radius: 4px;
}

.upload-docs img {
    height: 16px;
}

.upload-docs-right {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
}
.upload-docs-name{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: var(--md-text);
    line-height: 15px;
    color: #3A3A3A;
}
.upload-docs-size{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: var(--xs-text);
    line-height: 10px;
    color: #3A3A3A;
    text-align: left;
}