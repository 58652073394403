.success-page {
    overflow: hidden;
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    bottom: 70px;
    justify-content: center;
}

.success-page-title {
    height: 17px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #000000;
    max-width: 220px;
}

.success-page-btn {
    position: absolute;
    bottom: 43px;
    border: none;
    width: 119px;
    height: 42px;
    background: #59CE8F;
    text-align: center;
    color: #FFFFFF;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
}