.investigator-filter .accordion-item{
    background-color: transparent;
    border: none !important;
}
.investigator-filter .accordion {
    margin-bottom: 20px;
}
.investigator-filter .accordion-button:not(.collapsed){
    color: black;
    background-color: #fff;
    box-shadow: none;
    border: none !important;
    border-color: transparent !important;
}

.investigator-filter input{
    background: #FAFAFA !important;
    border-radius: 10px;
    border: none !important;
}
.investigator-filter .filterDropdown {
    width: 100%;
}
.investigator-filter .filterDropdown .dropdown-toggle {
    height: 42px !important;
    background: #FAFAFA;
    border-radius: 10px;
    text-align: left;
    border: unset;
    color: #8B919A !important;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 15px;
    position: relative;
    margin: 0 !important;
    outline: unset;
    box-shadow: unset !important;
}
.investigator-filter .filterDropdown .dropdown-toggle .selectedValue {
    color: #3A3A3A !important;
}
.investigator-filter .filterDropdown .dropdown-menu .dropdown-item:active {
    background-color: #E94D51;
}
.investigator-filter .filterDropdown .dropdown-toggle::after {
    right: 10px;
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
}
.accordion-button:not(.collapsed)::after{
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;    
}

.investigator-filter .header-text{
    margin: 14px 10px 5px 10px;
    padding: 0px 10px 0px 10px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: var(--xl-text);
    line-height: 19px;
    color: #3A3A3A;
}

.investigator-filter .accordion-item:first-of-type .accordion-button{
    padding: 10px 20px 10px 20px;
}

.investigator-filter .accordion-item:last-of-type .accordion-button.collapsed{
    padding: 10px 20px 10px 20px;
}

.investigator-filter .btn-list-container{
    margin-top: 0px;
    height: 45px;
}
.investigator-filter .btn-list{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 8px;
}

.investigator-filter .reset-btn, .investigator-filter .reset-btn:hover{
    color: #E94D51;
    background: #FFFFFF;
    border: 1px solid #E94D51;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    min-width: 58px;
    min-height: 25px;
    font-size: var(--sm-text);
}

.investigator-filter .apply-btn, .investigator-filter .apply-btn:hover{
    border: 1px solid #E94D51;
    border-radius: 10px;
    background: #E94D51;
}

.investigator-filter .btn, .investigator-filter .btn:hover{
    margin: 0px 15px 0px 0px;
    min-width: 58px;
    min-height: 25px;
    font-style: normal;
    font-weight: 500;
    font-size: var(--sm-text);
    width:58px;
    height:25px;
    padding: 0px;
    border-radius: 10px;
}

.investigator-filter .accordion-button::after{
    background-size: 15px;
}

.investigator-filter .accordion-button {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: var(--lg-text);
    line-height: 17px;
    color: #3A3A3A;
}

.investigator-filter .accordion-button:focus{
    border-color: gray;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13,110,253,.25);
}

.investigator-filter .input-container {
    position: relative;
}

.investigator-filter .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
}

.investigator-filter .dropdown-menu.show {
    display: block;
}
.investigator-filter .accordion-body {
    padding: 0 20px;
}

.investigator-filter .show.dropdown-menu {
    max-height: 100px;
    overflow-y: auto;
    min-width: 100%;
    margin: auto;
}
.accordion-header .accordion-button:focus {
    border-color: unset;
    box-shadow: unset;
}
.hide-number-dd::-webkit-outer-spin-button,
.hide-number-dd::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}