.investigator {
    background: linear-gradient(160.47deg, rgba(9, 132, 227, 0.1) 1.2%, rgba(233, 77, 81, 0.1) 97.21%);
    border: 1px solid #0984E3;
    box-shadow: 0px 4px 4px rgba(9, 132, 227, 0.25);
    border-radius: 10px;
}

.assign-investigator .row {
    margin: 0 0px 0 0px;
}

.assign-investigator .col-4,
.assign-investigator .row>* {
    padding: 0px 10px 0px 10px;
    margin-bottom: 10px;
}

.assign-investigator .card {
    display: flex;
    justify-content: center;
    margin: 0;
    height: 120px;
}

.sb-avatar {
    margin: auto;
    margin-bottom: 0px;
}

.investigator-name {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: var(--xs-text);
    line-height: 10px;
    text-align: center;
    color: #3A3A3A;
    margin-bottom: 3px;
    margin-top: 5px;
}

.investigator-state {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: var(--xs-text);
    line-height: 10px;
    text-align: center;
    color: #3A3A3A;
    margin-bottom: 3px;
}

.investigator-city {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: var(--xs-text);
    line-height: 10px;
    text-align: center;
    color: #3A3A3A;
    margin-bottom: 5px;
}

.sb-avatar__image {
    border: 1px solid #0984E3;
}

.selected-investigator{
    background: rgba(80, 80, 80, 0.5);
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(9, 132, 227, 0.25);
}

.selected-investigator .investigator-name {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: var(--sm-text);
    line-height: 10px;
    text-align: center;
    color: #FFFFFF;
}

.selected-lable {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: var(--sm-text);
    line-height: 12px;
    text-align: center;
    color: #FFFFFF;
}

.assign-investigator .sb-avatar{
    margin-top: 9px;
}
.p-circle {
    position: absolute;
    top: 8px;
    left: 8px;
    background-color: #fff;
    border-radius: 50%;
    height: 15px;
    width: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}