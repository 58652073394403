@media only screen and (min-width: 992px) {
    .app-wrapper {
        max-width: 390px;
        background-color: #fff;
        min-height: 100vh;
    }

    .navbar {
        padding: 0.5rem 10px;
    }

    /* .navbar-container { */
    /* left: 50% !important;
        right: unset !important;
        transform: translateX(-50%);
        max-width: 390px; */
    /* box-shadow: 0 0px 4px rgba(108, 92, 231, 0.25) !important; */
    /* } */
    /* .dashboard-banner-wrapper {
        padding: 14px 15px 20px 52px !important;
    }
    .dashboard-banner {
        padding: 8px !important;
    }
    .dashboard-banner .banner-img {
        height: 60px !important;
        left: -38px !important;
    } */
    /* .radialprocesschart-lower-btn {
        padding: 6px 12px !important;
    } */
    /* .dashboard-view-main{
        margin-bottom: 12px !important;
    } */
    /* .menu {
        display: none !important;
    } */
    .header-menu {
        align-items: flex-end;
        height: 100%;
        display: none;
    }

    .header-menu .menu {
        display: flex !important;
        position: unset;
        box-shadow: unset;
        background: unset;
        height: unset;
        width: unset;
        padding: 0;
    }

    .header-menu .menu-items {
        flex-direction: row;
        width: unset;
        height: unset;
        padding: 0 10px;
        margin: 0 10px;
        border-radius: unset !important;
        border-bottom: 2px solid transparent;
        margin-bottom: -0.5rem;
        padding-bottom: 0.75rem;
    }

    .header-menu .menu-items span {
        color: var(--primary-color);
        margin-left: 12px;
        font-size: var(--lg-text);
    }

    .header-menu .menu-items svg {
        width: 14px;
        height: 14px;
    }

    .header-menu .menu-items svg path {
        stroke: var(--primary-color);
    }

    .header-menu .menu-items.menu-items-active {
        border-color: var(--primary-color);
    }

    /* .card-value {
        margin-top: 5px !important;
    } */
    /* .opencasesblocks2-viewbtn {
        margin-top: 10px;
        Width: 70px;
        Height: 30px !important;
    } */
    .alert.alert-light {
        max-width: 360px;
        left: 50%;
        transform: translateX(-50%);
    }

    .modal .modal-dialog {
        max-width: 360px;
    }

    .AcceptButtons-Container {
        max-width: 360px;
    }

    .filter-component {
        max-width: 390px;
        margin: 0 auto;
        right: 0;
    }
}

.card-value.policyNo {
    white-space: nowrap;
}