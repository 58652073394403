.search-emp-page {
    padding: 20px 12px;
    padding-top: 20px;
    min-height: calc(100vh - 75px);
    display: flex;
    flex-direction: column;
}
.search-emp-card-list .emp-search-card .empMasterCard-child span,
.search-emp-card-list .emp-search-card .empMasterCard-child strong {
    color: #A0A0A0;
}
.search-emp-card-list .emp-search-card .empMasterCard-child strong.highlight {
    color: #3A3A3A;
    font-weight: 700;
}
.endOfResult {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #8F949B;
    margin: 50px 0 25px;
    text-align: center;
}
.search-emp-empty-msg {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 160px);
    flex: 1;
}
.search-emp-empty-text {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #8F949B;
    margin-top: 15px;
}
.search-empty-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
}
.emp-search-card .empMasterCard {
    margin: 25px 0 0 !important;
}