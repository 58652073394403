.OpencasesDetails-main {
  margin-top: 20px;
}

.OpencasesDetails-main .card {
  margin: 0 13px 8px 12px;
  background: linear-gradient(135deg, #0da44a 0%, #219c9c 77.06%);
  box-shadow: 0px 4px 5px rgba(26, 178, 117, 0.5);
  border-radius: 10px;
}

.AcceptButtons-ContainerParent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.AcceptButtons-Container {
  z-index: 1;
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 65px;
  width: 100%;
}

.open-clases-details-page .navbar-container {
  box-shadow: 0px 0px 4px rgba(26, 178, 117, 0.25);
}
