.addEmpDetails .navbar-container {
    box-shadow: var(--header-shadow-black);
}

.addEmpDetails-body{
    padding: 10px 12px;
}

.addEmpDetails-addAnother {
    padding: 10px 0px;
    width: 100%;
    margin-top: 33px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: var(--lg-text);
    line-height: 17px;
    text-align: center;
    color: #FFFFFF;
    background: #E94D51;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    border: none;
}

.addEmplDetails-bottom {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.addEmpDetails-or {
    margin-bottom: 10px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: var(--lg-text);
    line-height: 17px;
    text-align: center;
    color: #E94D51;
}

.addEmpDetails-bulk {
    margin-right: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: var(--lg-text);
    line-height: 17px;
    text-align: center;
    color: #505050;
}

.addEmpDetails-uploadExcel {
    margin-top: 15px;
    padding: 10px 0px;
    width: 100%;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: var(--lg-text);
    line-height: 17px;
    text-align: center;
    color: #505050;
    background: #FFFFFF;
    border: 1px solid #985290;
    box-shadow: 0px 4px 4px rgba(152, 82, 144, 0.25);
    border-radius: 10px;
}

.addEmpDetails-uploadExcel img {
    margin-left: 6px;
}

.download-template{
    color: #0984E3;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
    float: right;
    margin: 25px 5px 0 0;
    display: none;
}

.addEmpDetails .addEmpDetails-body .download-template {
    cursor: pointer;
    display: block; 
}