.acceptbuton-stylebtn {
    background: #00AF00;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: var(--lg-text);
    line-height: 17px;
    text-align: center;
    color: #FFFFFF;
    padding-top: 12px;
    width: 119px;
    height: 42px;
}

.dontacceptbuton-stylebtn {
    width: 119px;
    height: 42px;
    background: var(--primary-color);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: var(--lg-text);
    line-height: 17px;
    text-align: center;
    color: #FFFFFF;
    padding-top: 12px;
}

.acceptbutton-maindiv {
    flex-direction: row;
    display: flex;
}

.acceptbutton-flex {
    flex: 1;
    justify-content: center;
    display: flex;
}

.AcceptButtonsBtn {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: var(--lg-text);
    text-align: center;
    color: #FFFFFF;
    min-width: 119px;
    height: 42px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    line-height: 17px;
    border: none;
    outline: none;
    z-index: 1 !important;
    margin: auto;
    width: auto;
    white-space: nowrap;
}