.nav-padding {
    padding: 20px 10px 10px 10px;
}

.navbar-container {
    background: #fff;
    box-shadow: var(--header-shadow-black);
}
.mobile-Main .navbar-container {
    background: #fff;
    box-shadow: var(--header-shadow-black);
}
.navbarTop-navbar-brand{
    display: flex;
    align-items: baseline;
}

.logo-name {
    margin-left: 5px;
    vertical-align: bottom;
    color: black;
}

.logo-name1 {
    margin: 0px 0px 0px 5px;
    color: #3A3A3A;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.message-icon {
    cursor: pointer;
}

.bell-icon {
    cursor: pointer;
    margin-left: 20px;
}
.imageLogoMain {
    width: 48px;
    height: 40px;
}
.container-fluid{
    height: 100%;
}
.top-navbar .container-fluid {
    padding: 0;
}
.top-navbar {
    padding: 10px 8px;
}
.top-navbar .chase-logo {
    width: 120px;
    height: 30px;
}