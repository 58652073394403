.newInvoice-wrapper .input-container {
    margin: 0;
    padding: 10px 12px;
    margin-top: 0px;
    margin-bottom: 150px;
}
.serialNum {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.serialNum label {
    font-style: normal;
    font-weight: 400;
    font-size: var(--md-text);
    line-height: 15px;
    margin-top: 10px;
    color: #7C7C7C;
    margin-bottom: 3px;
    font-family: 'Inter', sans-serif;
}
.serialNum p {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #3A3A3A;
}
.multiField-row {
    position: relative;
    display: grid;
    grid-template-columns: 40px auto auto;
    align-items: center;
    padding: 10px;
}
.multiField-row > div {
    padding: 0;
}
.multiField-row > div:not(:first-child) {
    width: 100%;
    padding-left: 10px;
}
.addRowWrapper {
    display: flex;
    justify-content: flex-end;
    padding: 8px 10px 8px 10px;
}
.addRow {
    text-align: end;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #0984E3;
    cursor: pointer;
    border: unset;
    background: unset;
}
.chargesDetail {
    padding: 8px 10px 8px 10px;
}
.chargesDetail .chargesDetail-row {
    padding-left: 40px;
    display: grid;
    grid-template-columns: 50% 50%;
}
.chargesDetail .chargesDetail-row p {
    padding-left: 15px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #7C7C7C;
}
.newInvoice-wrapper .generic-status-update-input {
    background: rgba(5, 138, 238, 0.1);
    border: none;
}
.multiField-row .closeIcon {
    border: 1px solid #E42125;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px !important;
    height: 20px;
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: #fff;
    cursor: pointer;
    padding: 0 !important;
}
.multiField-row .closeIcon img {
    width: 10px;
    height: 10px;
}