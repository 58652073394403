.main-assesment {
    border-style: solid;
    border-width: 1px;
    background: #FFFFFF;
    box-shadow: 0px -1px 4px #418B81, 0px 2px 4px #418B81;
    border-radius: 10px;
    padding: 14px 6px;
    border-color: #418B81;
    margin-bottom: 20px;
}

.assesment-heading {
    font-style: normal;
    font-weight: 500;
    font-size: var(--md-text);
    line-height: 15px;
    color: #3A3A3A;
}

.assesment-maindiv {
    display: flex;
}

.assesment-barchart {
    flex: 2;
}

.bullet-assesment {
    flex: 1;
    align-self: flex-start;
}

.bulletname-assesment {
    color: #3A3A3A;
    font-weight: 500;
}

.assesmentreport-margin {
    margin-top: 15px;
}