.assign-docs {
    position: relative;
    margin: 10px 10px 5px 10px;
    width: calc(50% - 20px)!important;
    width: fit-content;
    padding: 8px;
    display: flex;
    align-items: center;
    background: #FAFAFA;
    border: 1px solid #0984E3;
    border-radius: 4px;
}

.assign-docs img {
    height: 16px;
}

.assign-docs-right {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
}

.assign-docs-name {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #3A3A3A;
}

.assign-docs-size {
    text-align: left;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 10px;
    color: #3A3A3A;
}

.rejected-docs-remove {
    border: 1px solid #E42125;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    position: absolute;
    right: -7px;
    top: -7px;
    background-color: #fff;
    cursor: pointer;
}

.rejected-docs-remove img {
    width: 10px;
    height: 10px;
}