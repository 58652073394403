.imageCTRLBtn {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: var(--md-text);
    margin-bottom: 2px;
    margin-top: 5px;
}

.imageCTRLMain {
    margin-top: 0 !important;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
}

.imageCtrlMargin {
    margin-left: auto;
    margin-right: auto;
}

.imageCtrlFlex {
    display: flex;
}

.imageContentMain {
    width: 65%;
    height: 65%;
}

.imageCTRLBtnStyle {
    width: 67.08px;
    height: 36px;
}

.imagectrlNewbtn {
    margin: 0px 6px 0px 0px;
    align-self: center;
}

@media screen and (min-width: 900px) {
    .mobileotpbottommargin {
        margin-top: 15px;
    }
}