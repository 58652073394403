.AssignInvestigatorSuccess-wrapper {
    position: relative;
    height: 100vh;
}

.AssignInvestigatorSuccess {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 35%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
}

.AssignInvestigatorSuccess-tick {
    max-width: 263px;
}

.AssignInvestigatorSuccess-title {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: var(--lg-text);
    line-height: 17px;
    text-align: center;
    color: #000000;
}

.AssignInvestigatorSuccess-btnDiv {
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    bottom: 10%;
}

.AssignInvestigatorSuccess-subtitle {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: var(--lg-text);
    line-height: 30px;
}