.commonnavbar .nav-padding {
    padding: 20px 10px 10px 10px;
}
.navbar-container{
    position: sticky;
    width: 100%;
    left: 0;
    right: 0;
    z-index: 11;
    top: 0;
    box-shadow: var(--header-shadow-black);
}

.casesDetails .navbar-container{
    box-shadow: 0px 0px 4px rgba(26, 178, 117, 0.25) !important;
}

.open-clases-details-page .navbar-container{
    box-shadow: 0px 0px 4px rgba(26, 178, 117, 0.25) !important;
}

.Alloted-case-page .navbar-container{
    box-shadow: 0px 0px 4px rgba(5, 138, 238, 0.25) !important;
}

.allotted-cases-details-page .navbar-container{
    box-shadow: 0px 0px 4px rgba(5, 138, 238, 0.25) !important;
}

.RejectedInvoices .navbar-container{
    box-shadow: 0px 0px 4px rgba(124, 20, 75, 0.25);
}

.reinvestigate-case-page .navbar-container{
    box-shadow: 0px 0px 4px rgba(235, 175, 0, 0.25) !important;
}

.commonnavbar .navbar-container {
    background: #fff;
    box-shadow: var(--header-shadow-black);
}

.assign-investigator .navbar-container{
    box-shadow: var(--header-shadow-blue) !important;
}

.empMasterPage .navbar-container{
    box-shadow: var(--header-shadow-pink);
}

.commonnavbar .logo-name {
    vertical-align: bottom;
    color: black;
}

.commonnavbar .message-icon {
    cursor: pointer;
}

.commonnavbar .bell-icon {
    cursor: pointer;
    margin-left: 20px;
}

.commonnavbar .search-icon {
    cursor: pointer;
    margin-left: 20px;
}

.commonnavbar .navbar {
    height: 75px;
    margin-bottom: 0;
    padding-top: 35px;
    padding-bottom: 8px;
}

.commonnavbar svg {
    margin-top: 6px;
}

.commonnavbar .title {
    margin-left: 10px;
    font-size: var(--xl-text);
    color: #3A3A3A;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 240px;
}

.commonnavbar .navbar-brand {
    padding-top: 0px;
    padding-bottom: 0px;
}
.navbar .title {
    margin-top: 3px;
}
.opencaseUploadInvestigationReport .navbar-container{
    box-shadow: 0px 0px 4px 0px rgba(26, 178, 117, 0.25);
}
.allottedcaseUploadInvestigationReport .navbar-container{
    box-shadow: 0px 0px 4px rgba(5, 138, 238, 0.25);
}
.reinvestigatecasesUploadInvestigationReport .navbar-container{
    box-shadow: 0px 0px 4px 0px rgba(235, 175, 0, 0.25);
}
.AssignedBlocksAllottedCases{
    box-shadow: 0px 0px 4px rgba(5, 138, 238, 0.25) !important;
}

.AssignedBlocksOpenCases {
    box-shadow: 0px 0px 4px rgba(26, 178, 117, 0.25) !important;
}
.AssignedBlocksReinCases{
    box-shadow: 0px 0px 4px 0px rgba(235, 175, 0, 0.25) !important;
}
.AssignedBlocksRejectedCases{
    box-shadow: 0px 0px 4px rgb(124, 20, 75, 0.25) !important;
}