.items-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.pagination-container img {
  width: 15px;
  height: 15px;
  margin-left: 4px;
}

.reactpaginate-bottom img {
  width: 15px;
  height: 15px;
  margin-left: 4px;
}

.page-active a {
  padding: 0 15px;
}

.item {
  width: 150px;
  height: 150px;
  margin: 10px;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--text-24);
  font-weight: bold;
}

.pagination-container {
  display: flex;
  justify-content: flex-start;
  margin-top: 0px;
  padding: 0px;
  margin-bottom: 0px;
}

.page {
  cursor: pointer;
  list-style-type: none;
  margin: 0 5px;
  padding: 2px;
  border-radius: 5px;
  background-color: transparent;
  color: #90949A;
  transition: background-color 0.3s ease-in-out;
}

.active {
  background-color: transparent;
  color: #3A3A3A;
}

.previous,
.next {
  cursor: pointer;
  margin: 0 0px;
  padding: 2px;
  list-style-type: none;
  border-radius: 5px;
  background-color: #f5f5f5;
  background-color: transparent;
  transition: background-color 0.3s ease-in-out;
}

.break-me {
  cursor: default;
  list-style-type: none;
  margin: 0 5px;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #f5f5f5;
}

[aria-disabled="true"],
[disabled] {
  text-decoration: none;
  color: #3A3A3A;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
}

[role=button] {
  text-decoration: none;
  color: #3A3A3A;
  font-size: var(--md-text);
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
}

.reactpaginate-main {
  margin: 5px 0px 5px 0px;
  width: 100%;
  flex-direction: row;
  align-items: center;
  display: flex;
  padding: 0px 10px 0px 10px;
  height: 45px;
}

.reactpaginate2 {
  width: 70%;
}

.reactpaginate3 {
  width: 30%;
  text-align: end;
  height: 100%;
  padding-top: 8px;
}

.reactpaginate-text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: var(--md-text);
  line-height: 15px;
  text-align: center;
  color: #8F949B;
  margin-bottom: 10px;
}

.reactpaginatesecond {
  justify-content: center;
}

.pagination-container2 {
  display: flex;
  justify-content: center;
  margin-top: 0px;
  padding: 0px;
}

.multi-select-wrapper {
  display: flex;
  align-items: center;
  padding: 0 10px 10px;
}

.multi-select-wrapper .reactpaginate-text {
  margin-bottom: 0;
  padding: 0 10px;
  width: calc(100% - 115px);
}

.multi-select-wrapper .select-btn {
  border-radius: 4px;
  background-color: #CCE6FF;
  border: unset;
  outline: unset;
  color: #004F99;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  box-shadow: unset;
  padding: 4px 8px;
  font-family: 'Inter', sans-serif;
  width: 55px;
  white-space: nowrap;
}

.multi-select-wrapper .cancel-btn {
  background-color: unset;
  border: unset;
  outline: unset;
  color: #505050;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  font-family: 'Inter', sans-serif;
  width: 60px;
  white-space: nowrap;
  text-align: right;
}