.filters {
    margin: 0 21px;
    display: flex;
    flex-direction: column;
}

.newfilters-title {
    margin: 14px 0px 8.5px 0px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #3A3A3A;
}

.filters-filter {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #8F949B;
    margin: 8.5px 0px;
    cursor: pointer;
}

.filters-filter-selected {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #3A3A3A;
    margin: 8.5px 0px;
    cursor: pointer;
}

.filters-filter:last-child {
    margin-bottom: 27px;
}

.report-modal-template .modal-body {
    padding: 0;
    height: calc(100vh - 2rem);
}

.filters-filter.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: all !important;
}

.filteractionsdropdown {
    padding: 5px 10px 5px 0px !important;
}

.filters-btns {
    display: flex;
    justify-content: flex-end;
    margin: 15px 0px;
}

.filter-btn-reset {
    margin-right: 15px;
    color: #E94D51;
    font-size: 10px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    outline: none;
    padding: 6px 15px 7px 15px;
    border-radius: 10px;
    border: 1px solid #E94D51;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.filter-btn-apply {
    font-size: 10px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    border: none;
    outline: none;
    padding: 6px 15px 7px 15px;
    border-radius: 10px;
    background: #E94D51;
    color: rgba(255, 255, 255, 1);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.custom-toggle::after {
    content: '';
    position: absolute;
    right: 10px;
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 3px solid black;
    transform: rotate(180deg);
}