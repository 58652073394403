.filter-parent {
    height: 100vh !important;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 1;
    z-index: 15;
    background-color: rgba(0, 0, 0, 0.5);
}

.filter-component {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0px;
    width: 100%;
    left: 0;
    z-index: 998 !important;
}

.filter-close {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: none;
    z-index: 998 !important;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.filter-div {
    border-radius: 20px 20px 0px 0px;
    left: 0px;
    background-color: #FFFFFF;
    z-index: 99 !important;
    opacity: 1;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width: 100%;
    margin-top: 10px;
    border: 1px solid lightgrey;
    max-height: calc(100vh - 100px);
    overflow-y: auto !important;
}

.assigned-blocks .filter-div {
    max-height: 500px;
    overflow-y: auto;
}

.CasesDetailsfilter .filter-div {
    max-height: 600px;
}

.CasesDetailsfilter.show-btns {
    padding-bottom: 120px;
}