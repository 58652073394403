.filter-btn {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: var(--md-text);
    text-align: center;
    padding: 5px 10px;
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    border-radius: 5px;
    cursor: pointer;
}
.filter-btn-active {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: var(--md-text);
    text-align: center;
    padding: 5px 10px;
    color: #FFFFFF;
    background: #E94D51;
    border-radius: 5px;
    cursor: pointer;
}
.filter-btn.disabled {
    pointer-events: none;
    opacity: 0.5;
    background: #b2b0b0;
  }
@media(max-width:390px){
    .filter-btn {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: var(--text-11);
        text-align: center;
        padding: 4px 8px;
        background: #FFFFFF;
        border: 1px solid #CCCCCC;
        border-radius: 5px;
    }
    .filter-btn-active{
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: var(--text-11);
        text-align: center;
        padding: 4px 8px;
        color: #FFFFFF;
        background: #E94D51;
        border-radius: 5px;
    }
}