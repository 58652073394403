.fact-finding-report-container {
    background-color: #fff;
    min-height: calc(100vh - 75px);
}

.moreOptions {
    padding: 15px 20px;
    display: flex;
    flex-direction: column;
}
.moreOptions-action {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #3A3A3A;
    margin-bottom: 15px;
    cursor: pointer;
}