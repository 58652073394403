.case{
   display: flex;
   flex-direction: column;
   justify-content: center;
   font-family: 'Inter', sans-serif;
   font-style: normal;
   width: 80px;
   height: 55px;
   border-radius: 10px;
   text-align: center;
   border: 1px solid #FFFFFF;
   color: #FFFFFF;
   line-height: normal !important;
   cursor: pointer;
}
.case-investigator{
   display: flex;
   flex-direction: column;
   justify-content: center;
   font-family: 'Inter', sans-serif;
   font-style: normal;
   width: 107px;
   height: auto;
   border-radius: 10px;
   text-align: center;
   border: 1px solid #FFFFFF;
   color: #FFFFFF;
   line-height: normal !important;
   cursor: pointer;
   padding: 5px;
}
.case:nth-child(1){
   padding: 0 15px;
}
.case:nth-child(2){
   padding: 0 10px;
}
.case .case-number {
   font-style: normal;
   font-weight: 700;
   font-size: var(--lg-text);
   line-height: 17px;
}

.case-investigator .case-number {
   font-style: normal;
   font-weight: 700;
   font-size: var(--lg-text);
   line-height: 17px;
}
.case .case-type{
   line-height: 100% !important;
   font-size: var(--sm-text);
}

.case-investigator .case-type{
   line-height: 100% !important;
   font-size: var(--sm-text) !important;
}