.documentUpload {
    margin: 14px 0px 0px 21px;
    position: relative;
    padding-bottom: 50px;
}

.documentUpload span {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #3A3A3A;
}

.documentUpload p {
    margin-top: 17px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #3A3A3A;
}

.custom-file-input {
    position: relative;
    height: 28px;
    overflow: hidden;
    width: 100%;
    padding: 2px;
}

.custom-file-input input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;
    border: none;
}

.custom-file-input::before {
    content: "Audio";
    display: inline-block;
    background: #fff;
    color: #3A3A3A;
    border-radius: 0px;
    padding: 8px 0 0 0;
    outline: none;
    white-space: nowrap;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    margin: auto;
    z-index: 0;
    font-style: normal;
}

.Videocustom-file-input {
    position: relative;
    height: 26px;
    overflow: hidden;
    width: 100%;
    padding: 2px;
}

.documentupload-main {
    margin: 10px 0px;
}

.Videocustom-file-input input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;
    border: none;
}

.Videocustom-file-input::before {
    content: "Video";
    display: inline-block;
    background: #fff;
    color: #3A3A3A;
    border-radius: 0px;
    padding: 8px 0 0 0;
    outline: none;
    white-space: nowrap;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    margin: auto;
    z-index: 0;
    font-style: normal;
}

.Imagescustom-file-input {
    position: relative;
    height: 28px;
    overflow: hidden;
    width: 100%;
    padding: 2px;
}

.Imagescustom-file-input input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;
    border: none;
}

.Imagescustom-file-input::before {
    content: "Images";
    display: inline-block;
    background: #fff;
    color: #3A3A3A;
    border-radius: 0px;
    padding: 8px 0 0 0;
    outline: none;
    white-space: nowrap;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    margin: auto;
    z-index: 0;
    font-style: normal;
}

.Documentscustom-file-input {
    position: relative;
    height: 26px;
    overflow: hidden;
    width: 100%;
    padding: 2px;
}

.Documentscustom-file-input input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;
    border: none;
}

.Documentscustom-file-input::before {
    content: "Documents";
    display: inline-block;
    background: #fff;
    color: #3A3A3A;
    border-radius: 0px;
    padding: 8px 0 0 0;
    outline: none;
    white-space: nowrap;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    margin: auto;
    z-index: 0;
    font-style: normal;
}

.submitbuttonupload {
    height: 28px;
    width: auto;
    font-size: 12px;
    margin-bottom: 10px;
    position: absolute !important;
    margin-right: 10px;
    margin-top: 11px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    text-align: center;
    color: #FFFFFF;
    background: #E94D51;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 10px;
    border: none;
    bottom: 10px;
    right: 10px;
}

.submitbuttonupload.disable {
    background: rgb(247, 189, 190);
    color: rgb(228, 33, 37);
}


.documentUploadassign-docs-right {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
}

.documentUploadassign-docs-name {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #3A3A3A;
    text-align: left;
}

.documentUploadassign-docs-size {
    text-align: left;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 10px;
    color: #3A3A3A;
}

.documentUploadassign-docs {
    position: relative;
    margin: 10px 0 5px 0px;
    width: calc(50% - 16px) !important;
    width: fit-content;
    padding: 8px;
    align-items: center;
    background: #FAFAFA;
    border: 1px solid #6C5CE7;
    border-radius: 4px;
    display: -webkit-inline-box;
}

.documentUploadassign-docs:nth-child(odd) {
    margin-right: 10px;
}

.assigned-blocks .documentUploadassign-docs {
    border: 1px solid #058AEE;
}

.AssignedBlocksReinCases .documentUploadassign-docs {
    border: 1px solid #EBAF00;
}

.AssignedBlocksOpenCases .documentUploadassign-docs {
    border: 1px solid #1AB275;
}

.newInvoice-wrapper .documentUploadassign-docs {
    border: 1px solid #7C144B;
}

.newUploadInvoiceallottedcase .documentUploadassign-docs {
    border: 1px solid #058AEE;
}

.opencaseUploadInvestigationReport .documentUploadassign-docs {
    border: 1px solid #1AB275;
}

.allottedcaseUploadInvestigationReport .documentUploadassign-docs {
    border: 1px solid #058AEE;
    box-shadow: 0px 4px 4px rgba(5, 138, 238, 0.25);
}

.reinvestigatecasesUploadInvestigationReport .documentUploadassign-docs {
    border: 1px solid #EBAF00;
}

.newUploadInvoiceopencase .documentUploadassign-docs {
    border: 1px solid #1AB275;
}

.newUploadInvoicereinvestigatecases .documentUploadassign-docs {
    border: 1px solid #EBAF00;
}

.documentUpload .supported-format-list {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 7px;
    line-height: 8px;
    color: #9F9F9F;
    margin: 2px 0 0 0;
}