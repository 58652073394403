.actions {
    margin-left: 21px;
    display: flex;
    flex-direction: column;
}

.actions-title {
    margin: 14px 0px 8.5px 0px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #E94D51;
}

.actions-action {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #3A3A3A;
    margin: 8.5px 0px;
    cursor: pointer;
}
.actions-action:last-child{
    margin-bottom: 27px;
}
.report-modal-template .modal-body {
    padding: 0;
    height: calc(100vh - 2rem);
}

.actions-action.disabled{
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: all !important;
}