.fieldset-wrapper {
    padding: 20px 12px;
    width: 100%;
}
.fieldset-wrapper label {
    color: #000;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 12px;
}
.fieldset-wrapper textarea {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #8F949B;
    border-radius: 10px;
    min-height: 92px;
    font-size: var(--md-text);
    padding: 10px;
    resize: none;
    -webkit-user-drag: none;
    -moz-user-drag: none;
    -ms-user-drag: none;
}
.accept-success-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    padding: 20px;
    text-align: center;
    background-color: #fff;
}
.accept-success-page-title {
    color: #000;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    max-width: 155px;
    margin: 40px 0 20px 0;
}
.accept-success-page-btn {
    color: #0984E3;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    background: unset;
    border: unset;
}