.assignInv-filter-main{
    margin: 10px 12px 10px 12px;
    text-align: end;
}
.assignInv-filter-main.prev-assign {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.assignInv-filter-placeing{
    text-align: end;
    background-color: transparent;
    border: none;
}

.assign-investigator .navbar-container{
    box-shadow: var(--header-shadow-blue);
}
.No-investigator-found-parent{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50%;
}
.No-investigator-found{
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #3A3A3A;
    margin-top: 15px;
}
.prev-assigned {
    display: inline-flex;
    align-items: center;
    color: #3A3A3A;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 10px;
    gap: 5px;
}
