.SuccessMessage-main {
    width: 100%;
    position: fixed;
    top: 80px;
    z-index: 11;
    left: 50%;
    transform: translateX(-50%);
    max-width: 390px;
}

.SuccessMessage-main2{
    align-items: center;
    background-color: #cff3cf;
    padding: 10px;
    border-radius: 5px;
    margin:10px;
    font-size: 12px;
}
@media only screen and (min-width: 992px)  {
    .SuccessMessage-main {
        max-width: 360px;
    }
}
