.message{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #fff;
    position: relative;
}
.backbtn {
    position: absolute;
    top: 85px;
    left: 12px;
    cursor: pointer;
}
.message-title {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #E94D51;
}
.message-desc {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #3A3A3A;
    margin-top: 15px;
}