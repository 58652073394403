.erroralert .modal-title {
    font-size: 16px;
}

.erroralert .modal-content{
    width: 300px;
    border-radius: 10px;
}

.erroralert .modal-header{
    border-bottom: none;
}

.erroralert .btn-close{
    background: transparent url(https://cima-im-cdn.itnext-dev.com/images/vendor/carbon_close-outline.png) center/2em auto no-repeat;
    opacity:1
}