.barchartmain {
    background: #22A6B3;
    filter: drop-shadow(-1px 0px 2px rgba(0, 0, 0, 0.2));
    border-radius: 0px;
}

.barchartmain2 {
    background: #9980FA;
    border-radius: 0px 5px 5px 0px;
    filter: drop-shadow(-1px 0px 2px rgba(0, 0, 0, 0.2));
}