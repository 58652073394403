.notification {
    padding: 20px 12px;
    padding-top: 95px;
    height: 100vh;
    background-color: #fff;
}

.notification-heading {
    font-weight: 500;
    font-size: var(--lg-text) !important;
    line-height: 17px;
    color: #3A3A3A;
}

.notification hr {
    margin: 10px 0px 14px 0px !important;
}

.notification-container {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 4px;
    background: #FCE9E9;
    padding: 10.5px 10px;
    margin: 16px 0px !important;
}

.notification-container-warning {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 4px;
    background: #FFFAE6;
    padding: 10.5px 10px;
    margin: 16px 0px !important;
}

.notification-container-p {
    display: block;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    margin-right: 25px;
    width: fit-content;
    margin-left: 16px;
    font-size: var(--text-13);
    line-height: 16px;
    color: #000000;
}

.notification-container-p span {
    font-weight: 700;
}

.notification-arrow {
    position: absolute;
    right: 20px;
}