.assigned-blocks .navbar-container{
    box-shadow: 0px 0px 4px rgb(26 178 117 / 25%);
}

.fir-gd-text{
    margin-top: 15px;
    margin-bottom: 3px;
}
.input-container{
    margin: 0 10px;
}
.petitioner-form-hrs hr{
    margin: 10px 24px 10px 23px;
}

.petitioner-form span {
    margin-left: 12px;
}
.addPetitioner-addAnother-parent{
    margin: 0px 13px 0px 12px;
}
.addPetitioner-addAnother {
    height: 37px;
    width: 100%;
    margin-top: 11px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #FFFFFF;
    background: #E94D51;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    border: none;
}
.allotted-block-form .navbar-container{
    box-shadow: 0px 0px 4px rgb(5 138 238 / 25%) !important;
}