.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    border-color: #fff #fff #E42125;
    color: #E42125;
}

.cardTabs {
    margin: 0 10px;
}

.reinvestigate-cases-details-page .cardTabs .nav-tabs {
    border-bottom: 1px solid rgba(235, 175, 0, 0.5);
    overflow-y: hidden;
}

.nav-tabs .nav-link {
    border: 2px solid transparent;
    color: #505050;
}

.nav {
    display: flex;
    flex-wrap: nowrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.nav-link {
    width: max-content;
}

.nav-bar-textstyle {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
}

.map-data {
    box-sizing: border-box;
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 0px 3px 0px 3px;
}

.map-data-breakDIV {
    width: 33.33%;
    margin-top: 15px;
    padding: 0 5px 0 5px;
}

.map-data-text1 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    word-wrap: break-word;
    color: #666666;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.map-data-text2 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    margin-top: 5px;
    font-size: 11px;
    line-height: 13px;
    word-wrap: break-word;
    color: #3A3A3A;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.alloted-claim-pleasewait{
    color : #0955e3;
    margin-top: 10px;
    font-size: 12px;
}
.rein-endorsement{
    margin-top: 12px;
}
.rein-endorsement .Label-Value{
    background: #FFFFFF;
    border-radius: 10px;
    margin-bottom: 10px;
    width: 100%;
    border: 1px solid #EBAF00;
}
.rein-dispatch-card{
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border: 1px solid #EBAF00;
    background: #FFFFFF;
    border-radius: 10px;
}
.rein-claim-maindiv {
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #EBAF00;
    border-radius: 10px;
    margin-top: 10px;
    padding: 5px;
    padding-bottom: 12px;
    position: relative;
}
.reininvestigator-maindiv{
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #EBAF00;
    border-radius: 10px;
    margin-top: 5px;
    padding: 5px;
    padding-bottom: 7px; 
}
.reinassignedblocks-mains {
    background: #FFFFFF;
    border: 1px solid #EBAF00;
    box-shadow: 0px 4px 4px rgba(249, 127, 81, 0.25);
    border-radius: 10px;
    margin: 4px;
    padding: 3px 3px 10px 3px;
    min-height: 70px;
}
.rein-document-title {
    margin: 0px 10px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: var(--md-text);
    color: #000000;
}
.rein-docs-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}
.rein-docs {  
    margin: 17px 10px;
    width: 42% !important;
    width: fit-content;
    padding: 8px;
    display: flex;
    align-items: center;
    background: #FAFAFA;
    border: 1px solid #F97F51;
    border-radius: 4px;
}

.rein-docs img {
    height: 16px;
}

.rein-docs-right {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
}

.rein-docs-name {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: var(--md-text);
    line-height: 15px;
    color: #3A3A3A;
}

.rein-docs-size {
    text-align: left;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: var(--xs-text);
    line-height: 10px;
    color: #3A3A3A;
}
.assignedInvestigators {
    width: 100%;
}
.assignedInvestigators .accordion-button {
    padding: 10px;
}
.reinvestigatedclaim-data .assignedInvestigators .accordion-button.collapsed {
    border-radius: 10px;
    border: unset;
    background: linear-gradient(135deg, rgba(218, 121, 52, 0.30) 0%, rgba(237, 185, 1, 0.30) 100%);
    box-shadow: 0px 4px 5px 0px rgba(198, 155, 27, 0.50);
    color: #3A3A3A;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.assignedInvestigators .accordion-item{
    border-radius: 10px;
}

.assignedInvestigators .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.reinvestigatedclaim-data {
    box-sizing: border-box;
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
}
.assignedInvestigators .accordion-button:not(.collapsed) {
    background: #FFF;
    color: #3A3A3A;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 10px;
    box-shadow: none;
    padding: 10px 10px 5px 10px;
    z-index: unset;
}

.reinvestigatedclaim-data .assignedInvestigators .accordion-item{
    border: 1px solid #EBAF00;
    background: #FFF;
}

.assignedInvestigators .accordion-button:not(.collapsed)::after{
    background-image:url("https://cima-im-cdn.itnext-dev.com/images/vendor/assign_not_collapsed.png") !important;
    transform: rotate(360deg);
    width: 17px;
    height: 9px;
}

.allotedclaim-upbuttton-imgreinv{
    width: 17px;
    height: 9px;
}

.assignedInvestigators{
    margin-top: 22px;
}

.assignedInvestigators .accordion-body .toggle img{
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform .2s ease-in-out;
    float: right;
}

.reinvestigatedclaim-data .assignedInvestigators .accordion-body .toggle {
    border: none;
    background: unset;
    outline: unset;
    margin: 0;
    padding-bottom: 0;
    margin-left: auto;
    display: block;
    padding-right: 0;
}
.reinvestigatedclaim-data .assignedInvestigators.Caseblocks .accordion-body .toggle {
    padding-right: 10px;
    padding-top: 10px;
    border: none;
    background: unset;
    outline: unset;
    margin: 0;
    padding-bottom: 0;
    margin-left: auto;
    display: block;
}

.assignedInvestigators .accordion-body .label{
    color: var(--black-color-70, #666);
    font-family: 'Inter', sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.assignedInvestigators .accordion-body .value{
    color: var(--black-color-90, #3A3A3A);
    font-family: 'Inter', sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.assignedInvestigators .accordion-body .col-4{
    margin-bottom: 15px;
}

.assignedInvestigators .accordion-body{
    padding: 10px 10px 15px 10px;
}

.Caseblocks .accordion-body{
    padding: 0px;
}

.Caseblocks .accordion-button{
    padding: 10px;
}

.NewBlocksAssigned .accordion-button{
    padding: 10px;
}

.reinvestigatedclaim-data .caseBlocks {
    border-radius: 10px;
    border: 1px solid #EBAF00;
    background: linear-gradient(135deg, rgba(218, 121, 52, 0.30) 0%, rgba(237, 185, 1, 0.30) 100%);
    box-shadow: 0px 4px 5px 0px rgba(198, 155, 27, 0.50);
    width: 100%;
    color: var(--black-color-90, #3A3A3A);
}

.reininvestigator-maindiv2{
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 10px;
    margin-top: 5px;
    padding-bottom: 7px; 
}
.new-assigned-block .accordion-body {
    padding-bottom: 5px;
}
.new-assigned-block .accordion-body .row {
    margin: 0 -5px;
    padding: 0;
}
.new-assigned-block .accordion-body .row > div {
    padding: 0 5px;
}
.reinvestigatedclaim-data .NewBlocksAssignedButton {
    display: flex;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    background: rgba(235, 175, 0, 0.30);
    color: var(--black-color-90, #3A3A3A);
    text-align: center;
    font-family: 'Inter', sans-serif;;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    border: 1px solid #EBAF00;
    border-radius: 10px;
    height: 100%;
    width: 100%;
}
.invList {
    margin: 0;
}
.reinvestigatedclaim-data .invList:not(:first-child) {
    border-top: 1px solid rgba(235, 175, 0, 0.50);
    padding-top: 15px;
}
.invList > * {
    padding: 0;
    padding-right: 15px;
}