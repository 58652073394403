.viewemployee-main {
    margin-top: 10px;
    padding:5px;
    display: flex;
    background: #FFFFFF;
    border: 1px solid #985290;
    box-shadow: 0px 4px 4px rgba(152, 82, 144, 0.25);
    border-radius: 10px;
    position: relative;
    padding-top: 10px;
}

.viewemployee-child {
    margin-left: 5px;
    display: flex;
}

.viewemployee-child2 {
    display: flex;
}

.viewemployee-container {
    width: 84.11%;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.location-input > button{
    /* padding: 0px; */
    /* padding-left: 15px; */
    height: 18px;
    background-color: none !important;
}

.location-input > button:hover{
    /* padding: 0px; */
    /* padding-left: 15px; */
    /* height: 18px !important; */
    /* background-color: #8F949B !important; */
    background-color: none !important;
}

.viewemployee-profile-btn {
    color: #FFFFFF;
    background: #21A366;
    border-radius: 10px;
    font-weight: 500;
    font-size: var(--sm-text);
    border: none;
}

.viewemployee-profile {
    margin-top: 10px;
    position: relative !important;
    width: 15.89%;
}
.viewemployee-profile-img{
    margin-top: 30px !important;
    margin-left: -10px;
    z-index: 1 !important;
    position: absolute;
}

.viewemployee-text1 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: var(--md-text);
    text-align: center;
    color: #8F949B;
}

.viewemployee-text2 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: var(--md-text);
    text-align: center;
    color: #505050;
}

.viewempset{
    width:50%;
    text-align:start;
}
.editEmployeeinput {
    height: 20px;
    width: 50%;
    background: rgba(152, 82, 144, 0.1);
    border-radius: 5px;
    font-size: var(--sm-text);
    padding:3px;
    border: none;
    outline: none;
}

.cityInputField {
    height: 20px;
    background: inherit;
    font-size: var(--sm-text);
    padding:3px;
    border: none;
    outline: none;
}

.editemployeemain {
   margin-top: 5px;
}

.viewemployee-main .closeIcon {
    border: 1px solid #E42125;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    position: absolute;
    right: -7px;
    top: -7px;
    background-color: #fff;
    cursor: pointer;
}
.viewemployee-main .closeIcon img {
    width: 10px;
    height: 10px;
}
.editemployeemain .dropdown-toggle.btn{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:0 4px !important;
}
.editemployeemain .dropdown-menu {
    width: 100%;
    max-height: 52vh;
    margin-top: 18px;
    transform: none !important;
  }

  .editemployeemain .cityDropdown .dropdown-menu {
    width: 100%;
    max-height: 52vh;
    margin-top: 18px;
    transform: none !important;
  }

.form-check-input {
    border-color: #E94D51 !important;
}

.form-check-input:checked {
    background-color: #e94d51 !important;
    border-color: #E94D51 !important;
}

.addMoreStatesBtnActive{
    color: #23AEF4;
    font-weight: 600;
    cursor: pointer;
    font-size: 12px;
    border: none;
    background: none;
}

.addMoreStatesBtnNotactive{
    cursor: not-allowed !important;
}