.docxOpener-maindiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.docxOpener-innerdiv {
  width: 100vw;
  height: 79vh;
}

#proxy-renderer,
#msdoc-renderer {
  height: 100%;
}
