.assign-investigator .btn,.assign-investigator .btn-secondary:hover {
    background: rgba(89, 206, 143, 0.5);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: var(--lg-text);
    line-height: 17px;
    text-align: center;
    color: #FFFFFF;
    border: 1px solid rgba(89, 206, 143, 0.5);
    min-width: 119px;
    min-height: 42px;
}

.newbtn-container{
    position: fixed;
    bottom: 43px;
    left: 0;
    right: 0;
    width: 100%;
    text-align: center;
}

.assign-investigator.selected .btn,.assign-investigator.selected .btn-secondary:hover {
    background: #59CE8F;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: var(--lg-text);
    line-height: 17px;
    text-align: center;
    color: #FFFFFF;
    border: 1px solid rgba(89, 206, 143, 0.5);
    min-width: 119px;
    min-height: 42px;
    width: auto !important;
}

.assignbtn-custom{
    margin: 0px;
    padding: 0px;
    height: 42px;
    border-radius: 20px;
    width: 119px;
}