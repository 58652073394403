.assignblock-submitBtn{
    position: fixed;
    top: 400px;
}

.AssignedBlocksOpenCases .petitioner-input{
    background: rgba(9, 132, 227, 0.1);
}
.AssignedBlocksOpenCases .navbar-container{
    box-shadow: 0px 0px 4px rgb(26 178 117 / 25%);
}
