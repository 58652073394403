/* Common Styles */
.card {
  margin: 0 13px 15px 12px;
  background: linear-gradient(135deg, #0da44a 0%, #219c9c 77.06%);
  box-shadow: 0px 4px 5px rgba(26, 178, 117, 0.5);
  border-radius: 10px;
}

.card-mb {
  margin-bottom: 13px;
  margin-top: 12px;
  padding: 0 8px;
}

.card-body {
  padding: 0;
}

.card-case {
  margin-bottom: 8px;
  padding: 5px 8px 0;
}

.card-lable,
.card-case-lable {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: var(--sm-text);
  line-height: 12px;
  color: #ffffff;
}

.card-value,
.card-case-value {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: var(--text-11);
  line-height: 13px;
  color: #ffffff;
  margin-top: 2px;
}

.btn,
.btn-secondary:hover {
  background: var(--primary-color);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: var(--sm-text);
  line-height: 12px;
  color: #ffffff;
}

.details-view-btn {
  position: absolute;
  right: 8px;
  bottom: 10px;
}

.icon-end {
  float: right;
}

#info {
  display: flex;
}
#info-icon {
  height: 12px;
  position: absolute;
  right: 12px;
}
#tooltip-position {
  position: absolute;
  right: 12px;
}

@media (max-width: 410px) {
  .details-card {
    margin: 12px 6px 12px 0;
  }
}

@media (max-width: 401px) {
  .details-card {
    margin: 12px 0 12px 0;
  }
}

/* Specific Styles */

/* Case Type Names */
.caseTypeNameOpen,
.caseTypeNameAllotted,
.caseTypeNameReinvestigate,
.caseTypeNameRejected {
  border-radius: 10px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -10px;
  padding: 4px 10px;
}

.caseTypeNameOpen {
  background: #ddf4eb;
  border: 1px solid #1ab275;
  color: #006039;
}

.caseTypeNameAllotted {
  background: #daeefd;
  border: 1px solid #058aee;
  color: #01599c;
}

.caseTypeNameReinvestigate {
  background: #ecdce4;
  border: 1px solid #ebaf00;
  color: #977000;
}

.caseTypeNameRejected {
  background: #ecdce4;
  border: 1px solid #7c144b;
  color: #690038;
}

/* Buttons */
.buttonOpenCases,
.buttonallotedcases,
.buttonreincases {
  display: flex;
  align-items: center;
  gap: 4px;
  height: 25px;
  padding: 6px 10px;
  white-space: nowrap;
  border: unset;
  border-radius: 10px;
  background: #e42125;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: #fff;
  font-size: 10px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
}

/* Case Types Backgrounds */
.casesDetails-card .card {
  margin: 0 13px 8px 12px;
  background: linear-gradient(135deg, #0da44a 0%, #219c9c 77.06%);
  box-shadow: 0px 4px 5px rgba(26, 178, 117, 0.5);
  border-radius: 10px;
}

.Alloted-case-page .card,
.Allotted-cases-details-page .card,
.allotted-cases-details-page .card {
  background: linear-gradient(135deg, #1ba2a2 0%, #0084ff 100%);
  box-shadow: 0px 4px 5px rgba(5, 138, 238, 0.5);
}

.reinvestigate-case-page .card,
.reinvestigate-cases-details-page .card,
.rein-caseCard .card {
  background: linear-gradient(135deg, #da7934 0%, #edb901 100%);
  box-shadow: 0px 4px 5px rgba(198, 155, 27, 0.5);
}

.RejectedInvoices .card,
.rejected-cases-details-page .card {
  background: linear-gradient(121.96deg, #8b1453 0%, #561437 63.64%);
  box-shadow: 0px 4px 5px rgba(124, 20, 75, 0.5);
}

/* Shadows */
.card-shadow {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: unset;
}

.card-shadow .center-circle {
  border-radius: 50%;
  border: 1px solid #fff;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.details-view-btn {
  position: absolute;
  right: 8px;
  bottom: 10px;
}

.view-btn {
  display: flex;
  white-space: nowrap;
  border: unset;
  display: flex;
  align-items: center;
  gap: 4px;
  height: 25px;
  padding: 6px 10px 6.75px 9px;
  white-space: nowrap;
  border: unset;
  border-radius: 10px;
  background: #e42125;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #fff;
  font-size: 10px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
}
