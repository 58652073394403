
.petitioner-form span {
    margin-left: 12px;
}

.petitioner-form-container {
    margin: 7px 14px 0px 14px;
}
.petitioner-form-container .textinput-genricpet-main, .petitioner-form-container .numberinput-genric-main {
    padding: 0 10px;
    margin-top: 1px;
}
.petitioner-form-label {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 3px;
    color: #7C7C7C;
}

.petitioner-details-container{
    margin-top: 16px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.petitioner-details-remove{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #E42125;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background: #FFFFFF;
    padding: 5px;
    border-radius: 50%;
    margin-bottom: 4px;
}

.petitioner-input {
    padding-left: 9px;
    margin-bottom: 1px;
    width: 100%;
    font-size: var(--md-text);
    outline: none;
    border: none;
    height: 37px;
    background: rgba(108, 92, 231, 0.1);
    border-radius: 5px;
}

.addPetitioner-addAnother-parent{
    margin: 0px 13px 0px 12px;
}

.addPetitioner-addAnother {
    height: 37px;
    width: 100%;
    margin-top: 11px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #FFFFFF;
    background: #E94D51;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    border: none;
}