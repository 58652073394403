.loaderWrapper{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}
.loader {
    border: 10px solid #f3f3f3;
    border-top: 10px solid var(--primary-color);
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 1s linear infinite;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 
.loaderGif {
  width: 320px;
  height: 320px;
  max-width: 100%;
  max-height: 100%;
}