.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    border-color: #fff #fff var(--primary-color);
    color: var(--primary-color);
}

.cardTabs {
    margin: 0 10px;
}

.open-clases-details-page .cardTabs .nav-tabs {
    border-bottom: 1px solid rgba(26, 178, 117, 0.5);
    overflow-y: hidden;
}

.nav-tabs .nav-link {
    border: 2px solid transparent;
    color: #505050;
}

.nav {
    display: flex;
    flex-wrap: nowrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.nav-link {
    width: max-content;
}

.policy hr {
    border: 1px solid #1AB275;
}

.map-data {
    box-sizing: border-box;
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 0px 3px 0px 3px;
}
.endorsement .map-data-breakDIV {
    margin-top: 10px;
    padding: 3px 6px 2px 6px;
}
.map-data-breakDIV {
    width: 33.33%;
    margin-top: 15px;
    padding: 3px 8px 2px 8px;
}

.map-data-text1 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: var(--sm-text);
    line-height: 12px;
    word-wrap: break-word;
    color: #666666;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.map-data-text2 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    margin-top: 3px;
    font-size: var(--text-11);
    word-wrap: break-word;
    line-height: 13px;
    color: #3A3A3A;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.nav-bar-textstyle {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: var(--lg-text);
    line-height: 17px;
    text-align: center;
}

.riskgreentick-mainstyle{
    display: flex;
}

.riskgreentick-style {
    height: 15px;
    width: 15px;
}

.document-title {
    margin: 0px 10px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: var(--md-text);
    color: #000000;
}

.docs-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.open-clases-details-page .docs {
    margin: 17px 10px;
    padding: 8px;
    display: flex;
    align-items: center;
    background: #FAFAFA;
    border: 1px solid #0984E3;
    border-radius: 4px;
    width: 42%;
}

.docs img {
    height: 16px;
}

.docs-right {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
}

.docs-name {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: var(--md-text);
    line-height: 15px;
    color: #3A3A3A;
}

.docs-size {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: var(--xs-text);
    line-height: 10px;
    color: #3A3A3A;
    text-align: left;
}

.endorsement:first-child{
    margin-top: 12px;
}
.open-endorsement:first-child{
    margin-top: 12px;
}
.open-endorsement .Label-Value{
    background: #FFFFFF;
    border-radius: 10px;
    margin-bottom: 10px;
    width: 100%;
   border: 1px solid #1AB275 !important;
}

.endorsement .Label-Value{
    background: #FFFFFF;
    border: 1px solid #058AEE;
    border-radius: 10px;
    margin-bottom: 10px;
    width: 100%;
}

.endorsement .card{
    background: #fff;
    box-shadow: none;
    border: 1px solid #0984E3;
}

.claim-maindiv {
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #1AB275;
    border-radius: 10px;
    margin-top: 10px;
    padding: 5px;
    padding-bottom: 12px;
    position: relative;
}

.claim-data{
    box-sizing: border-box;
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: row;
    width:100%;
}

.claim-div-data{
    width: 100%;
}

.claimview-btn {
    background: var(--primary-color);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: transparent;
    border-radius: 10px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: var(--sm-text);
    line-height: 12px;
    color: #FFFFFF;
    Width: 64px;
    Height: 25px;
}

.claimbtn-main{
    text-align: end;
}

.claim-upbuttton-style{
    width: 20px;
    margin: 0px;
    height: 20px;
    padding: 0px;
    border: transparent;
    background-color: transparent;
}

.claim-upbuttton-img{
    width: 12px;
    height: 7px;
}

.claim-upbuttton-mainstyle{
    width: 100%;
    text-align: end;
    left: -10px;
    position: absolute;
}
.claim-upbuttton-mainstyle2{
    width: 100%;
    text-align: end;
    padding-right: 5px;
}

.claim-map-data-breakDIV{
    width: 33.33%;
    margin-top: 7px;
    padding: 0 3px 0 3px;   
}

.claim-document-title{
    margin: 0px 3px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: var(--md-text);
    color: #000000;
}

.claim-pleasewait{
    color : #525458;
    margin-top: 10px;
    font-size: var(--md-text);
}
.dispatch{
    margin-top: 12px;
}
.dispatch-card{
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border: 1px solid #1AB275;
    background: #FFFFFF;
    border-radius: 10px;
}
.map-data-breakdiv {
    width: 33.33%;
    margin: 7.5px 0px;
    padding: 0 5px 0 5px;
}

.opencasesblocks2-mains{
    width: 31%;
    background: #FFFFFF;
    border: 1px solid #1AB275;
    border-radius: 10px;
    margin: 4px;
    padding: 3px 3px 10px 3px;
    min-height: 70px;
}

.opencasesblocks2-maindiv{
    padding-top: 5px;
}

.opencasesblocks2-viewbtn {
    background: var(--primary-color);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: transparent;
    border-radius: 10px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: var(--sm-text);
    line-height: 12px;
    color: #FFFFFF;
    Width: 60px;
    Height: 20px;
}


.opencaseblocks-block-width{
    width: 31%;
}

@media screen and (max-width: 375px) {
    .opencaseblocks-block-width {
        width: 30%;
    }
}
@media screen and (max-width: 377px) {
    .opencasesblocks2-mains{
        width: 30% !important;
        background: #FFFFFF;
        border: 1px solid #1AB275;
        border-radius: 10px;
        margin: 4px;
        padding: 3px 3px 10px 3px;
        min-height: 70px;
    }
}

.Claimdocument-title {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: var(--md-text);
    color: #000000;
    padding: 0 10px;
}

.claimdcname {
    font-size: 10px;
}

.policyStyle{
    font-size: 10px;
}


.openinvblocks-mains {
    border: 1px solid #1AB275;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(26, 178, 117, 0.25);
    border-radius: 10px;
    margin: 4px;
    padding: 3px 3px 10px 3px;
    min-height: 70px;
}
.openinvclaim-data .assignedInvestigators .accordion-button.collapsed {
    border-radius: 10px;
    border: unset;
    background: linear-gradient(135deg, rgba(13, 164, 74, 0.30) 0%, rgba(33, 156, 156, 0.30) 77.06%);
    box-shadow: 0px 4px 5px 0px rgba(26, 178, 117, 0.50);
    color: #3A3A3A;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.assignedInvestigators .accordion-item {
    border-radius: 10px;
}
.assignedInvestigators .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}
.openinvclaim-data {
    box-sizing: border-box;
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
}
.assignedInvestigators .accordion-button:not(.collapsed) {
    background: #FFF;
    color: #3A3A3A;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 10px;
    box-shadow: none;
    z-index: unset;
    padding: 10px 10px 5px 10px;
}
.openinvclaim-data .assignedInvestigators .accordion-item {
    border: 1px solid #1AB275;
    background: #FFF;
}
.openinvclaim-data .assignedInvestigators .accordion-button:not(.collapsed)::after{
    background-image:url("https://cima-im-cdn.itnext-dev.com/images/vendor/open_not_collapsed.png") !important;
    transform: rotate(360deg);
    width: 17px;
    height: 9px;
}
.allotedclaim-upbuttton-imgreinv{
    width: 17px;
    height: 9px;
}
.assignedInvestigators{
    margin-top: 22px;
}
.assignedInvestigators .accordion-body .toggle img{
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform .2s ease-in-out;
    float: right;
}

.openinvclaim-data .assignedInvestigators .accordion-body .toggle {
    border: none;
    background: unset;
    outline: unset;
    margin: 0;
    padding-bottom: 0;
    margin-left: auto;
    display: block;
    padding-right: 0;
}
.openinvclaim-data .assignedInvestigators.Caseblocks .accordion-body .toggle {
    padding-right: 10px;
    padding-top: 10px;
    border: none;
    background: unset;
    outline: unset;
    margin: 0;
    padding-bottom: 0;
    margin-left: auto;
    display: block;
}
.assignedInvestigators .accordion-body .label{
    color: var(--black-color-70, #666);
    font-family: 'Inter', sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.assignedInvestigators .accordion-body .value{
    color: var(--black-color-90, #3A3A3A);
    font-family: 'Inter', sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.assignedInvestigators .accordion-body .col-4{
    margin-bottom: 15px;
}
.assignedInvestigators .accordion-body{
    padding: 10px 10px 15px 10px;
}
.Caseblocks .accordion-body{
    padding: 0px;
}
.Caseblocks .accordion-button{
    padding: 10px;
}
.openinvclaim-data .caseBlocks {
    border-radius: 10px;
    border: 1px solid #1AB275;
    background: linear-gradient(135deg, rgba(13, 164, 74, 0.30) 0%, rgba(33, 156, 156, 0.30) 77.06%);
    box-shadow: 0px 4px 5px 0px rgba(26, 178, 117, 0.50);
    width: 100%;
    color: var(--black-color-90, #3A3A3A);
}
.reininvestigator-maindiv2{
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 10px;
    margin-top: 5px;
    padding-bottom: 7px; 
}
.new-assigned-block .accordion-body {
    padding-bottom: 5px;
}
.new-assigned-block .accordion-body .row {
    margin: 0 -5px;
    padding: 0;
}
.new-assigned-block .accordion-body .row > div {
    padding: 0 5px;
}
.openinvclaim-data .assignedInvestigators.NewBlocksAssigned .accordion-button:not(.collapsed)::after{
    display: none !important;
}
.NewBlocksAssigned .accordion-button{
    padding: 10px;
}
.openinvclaim-data .NewBlocksAssignedButton {
    display: flex;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    background: rgba(26, 178, 117, 0.30);
    color: var(--black-color-90, #3A3A3A);
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    border: 1px solid #1AB275;
    border-radius: 10px;
    height: 100%;
    width: 100%;
    cursor: unset;
    min-height: 32px;
}