.UploadInvoiceFormBottom hr {
    margin: 18px 0 0px 0;
    border: 1px solid #8F949B;
}

.UploadInvoiceFormBottom-title {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
    margin: 0;
}
.UploadInvoiceFormBottom .upload-btn img {
    margin: 0;
}
.UploadInvoiceFormBottom .assignBlockdocs-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 22px 0 26px 0;
    padding: 0 10px;
}

.UploadInvoiceFormBottom .assign-docs-parent{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 10px;
}
.UploadInvoiceFormBottom .assign-docs {
    min-width: 148px;
    width: fit-content;
    padding: 8px;
    display: flex;
    align-items: center;
    background: #FAFAFA;
    border: 1px solid #00B894;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
}

.UploadInvoiceFormBottom .assign-docs img {
    height: 16px;
}

.UploadInvoiceFormBottom .assign-docs-right {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    margin-right: 20px;
}
.documentListContainer {
    display: flex;
    flex-wrap: wrap;
}

.UploadInvoiceFormBottom .assign-docs-name {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #3A3A3A;
}

.UploadInvoiceFormBottom .assign-docs-size {
    text-align: left;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 10px;
    color: #3A3A3A;
}

.UploadInvoiceFormBottom .upload-btn {
    display: flex;
    align-items: center;
    margin-top: 0;
    width: auto;
    height: 25px;
    background: #E94D51;
    border-radius: 10px;
    padding: 0 10px;
    border: none;
    cursor: pointer;
}

.UploadInvoiceFormBottom .upload-btn span{
    font-size: 10px;
    color: #FFFFFF;
    margin-left: 8.33px;
}

.UploadInvoiceFormBottom textarea{
    padding-left: 10px;
    border-radius: 10px;
    width: 100%;
    height: 92px;
    outline: none;
    border: 1px solid #8F949B;
}

.UploadInvoiceFormBottom-textarea{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    color: #8F949B;
    margin: 17px 15px 0 10px;
}

.UploadInvoiceFormBottom{
    margin-bottom: 20px;
}

.UploadInvoiceFormBottom-textarea textarea{
    padding: 10px;
    resize: none;
    /* user-drag: none; */
    -webkit-user-drag: none;
    -moz-user-drag: none;
    -ms-user-drag: none;
}

.rejected-cases-details-page hr{
    display: none;
}