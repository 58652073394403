.main-performance {
    border-style: solid;
    border-width: 1px;
    background: #FFFFFF;
    box-shadow: 0px -1px 4px #0079C5, 0px 2px 4px #0079C5;
    border-radius: 10px;
    color: #0079C5;
    padding: 14px 6px;
}

.main2-performance {
    display: flex;
    padding-bottom: 10px;
}

.performance-heading {
    font-style: normal;
    font-weight: 500;
    font-size: var(--md-text);
    width: 50%;
    color: #3A3A3A;
}

.performance-div-btn {
    display: flex;
    width: 50%;
    justify-content: flex-end;
    gap: 3px;
}

.performance-btn1 {
    font-style: normal;
    font-weight: 500;
    font-size: var(--sm-text);
    border: transparent;
    background-color: transparent;
    padding: 0px;
    align-self: start;
}

.performance-btn2 {
    font-style: normal;
    font-weight: 500;
    font-size: var(--sm-text);
    border: transparent;
    background-color: transparent;
    padding: 0px;
    align-self: start;
}

.performance-btn3-div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 14px;
}

.performance-btn3:first-child {
    font-size: var(--text-11) !important;
}

.performance-btn3 {
    white-space: nowrap;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: var(--md-text);
    line-height: 15px;
    color: #90949A;
    border-radius: 4px;
    background: #FFFFFF;
    border: 1px solid #90949A;
    box-shadow: 0px 2px 4px rgba(144, 148, 154, 0.5);
}

.performance-btn3.selected {
    color: #FFFFFF;
    background: #E42125;
    box-shadow: 0px 2px 4px rgba(233, 77, 81, 0.5);
    border: 1px solid #E42125;
}

.newPRbuttonstyle {
    flex-direction: row;
    display: flex;
}

.viewreportbtnnew {
    flex: 1;
}

.percentageviewcss {
    margin: 0;
    flex: 1;
    align-self: flex-end;
}

.performance-width100 {
    width: 100%;
}

.performance-lower-div {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding-left: 12px;
    padding-right: 12px;
}

.performance-lower-btn {
    font-size: var(--sm-text);
    border: transparent;
    background-color: var(--primary-color);
    border-radius: 12px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: #FFFFFF;
    font-weight: 500;
    padding: 5px;
    padding-left: 12px;
    padding-right: 12px;
}

.performance-lower-p {
    color: #8F949B;
    font-style: normal;
    font-weight: 500;
    font-size: var(--sm-text);
    margin-top: 10px;
    margin-bottom: 0px
}

.performance-reportbutton {
    font-weight: 500;
    font-size: var(--md-text);
    text-decoration-line: underline;
    color: #2CB0F2;
    background: transparent;
    border: transparent;
    font-style: normal;
    line-height: 15px;
}

.main3-employee {
    display: flex;
    margin-bottom: 5px;
}

.header-performance {
    width: 70%;
    font-style: normal;
    font-weight: 600;
    font-size: var(--xl-text);
    color: #3A3A3A;
}

.header2-performance {
    width: 30%;
    text-align: end;
}

@media(max-width:380px) {
    .performance-btn3 {
        font-size: var(--text-11);

    }
}

@media(max-width:335px) {
    .performance-btn3 {
        font-size: var(--sm-text);
    }
}