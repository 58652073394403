.empChangeStatus-body {
    padding: 10px;
    padding-bottom: 150px;
}

.filterBtns-filterPage {
    display: flex;
    flex-direction: row;
}

.filterBtns-filterPage:only-child {
    margin-left: 10px !important;
}

.empChangeStatus-mark {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: var(--md-text);
    line-height: 15px;
    color: #3A3A3A;
}

.empChangeStatus-body .filterBtn-container {
    margin: 12px 0 15px 0;
    display: flex;
    flex-direction: row;
}

.empChangeStatus-body .filterBtn-container-btn {
    margin-right: 10px;
}

.empChangeStatus-calender {
    margin-bottom: 15px !important;
}

.empChangeStatus-calender label {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: var(--sm-text);
    line-height: 12px;
    color: #3A3A3A;
}

.filter-calender {
    border: 1px solid #8F949B;
    width: 106px;
    height: 25px;
}

.filter-calender input {
    display: none;
}

.empChangeStatusBtn {
    margin-top: 100px;
}

.empChangeStatusBtn-div {
    display: flex;
    justify-content: center;
}

@media screen and (min-width: 768px) {
    .empChangeStatusBtn {
        bottom: 10;
        margin-top: 70px;
    }
}

.EmpMasterChangeStatus-datepicker {
    display: flex;
}

.EmpMasterChangeStatus-datepicker2 {
    margin-left: 5px;
}

.EmpMasterChangeStatus-datepicker1 {
    margin-right: 5px;
}

.empmasterSubmitbuttonNew {
    bottom: 50px;
    position: absolute;
}

.EmpMasterChangeStatus-datepicker1 .react-datepicker-popper[data-placement=bottom-end] .react-datepicker__triangle,
.EmpMasterChangeStatus-datepicker1 .react-datepicker-popper[data-placement=top-end] .react-datepicker__triangle {
    left: 0 !important;
    right: 0 !important;
}

.EmpMasterChangeStatus-datepicker2 .react-datepicker-popper[data-placement=bottom-end] .react-datepicker__triangle,
.EmpMasterChangeStatus-datepicker2 .react-datepicker-popper[data-placement=top-end] .react-datepicker__triangle {
    left: 0 !important;
    right: 0 !important;
}