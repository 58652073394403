.empMasterCard {
    width: 100%;
    display: flex;
    min-height: 134px;
    background: linear-gradient(97.68deg, rgba(152, 82, 144, 0.1) 0%, rgba(0, 121, 197, 0.1) 100%);
    border: 1px solid #985290;
    box-shadow: 0px 4px 4px #985290;
    border-radius: 10px;
    margin: 5px 0px !important;
}
.empMasterCard.chageStatusCard {
    position: relative;
    padding-top: 24px;
    height: unset;
}
.empMasterCard .closeIcon {
    border: 1px solid #E42125;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 7px;
    top: 7px;
    cursor: pointer;
}
.empMasterCard .closeIcon img {
    width: 10px;
    height: 10px;
}
.empMasterCard-profile {
    margin-top: 12px;
    width: 15.89%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 70px;
}

.empMasterCard-profile-btn {
    margin-top: 5px;
    width: 49px;
    height: 20px;
    color: #FFFFFF;
    border-radius: 10px;
    font-weight: 500;
    font-size: var(--sm-text);
    border: none;
    padding: 0 !important;
}

.empMasterCard-profile .active {
    background: #21A366 !important;
}

.empMasterCard-profile .inactive {
    background: #E94D51 !important;
}

.empMasterCard-profile .onleave {
    background: #8F949B !important;
}

.empMasterCard-container {
    width: 84.11%;
    display: flex;
    flex-direction: column;
    padding: 12px 7px;
}

.empMasterCard-child {
    margin-left: 5px;
    display: flex;
    flex-direction: column;
}

.empMasterCard-line1 {
    display: flex;
    justify-content: space-between;
}

.empMasterCard-line2 {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.empMasterCard-line3 {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.empMasterCard-child span {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: var(--sm-text);
    line-height: 12px;
    color: #666666;
    text-transform: capitalize;
}

.empMasterCard-child strong {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: var(--text-11);
    line-height: 13px;
    color: #3A3A3A;
    margin-top: 2px;
}