.profile-img-circle {
    position: relative;
    width: 50px;
    height: 50px;
    text-align: center;
    border-radius: 100%;
}
.profile-img-circle > span {
    border-radius: 100%;
    overflow: hidden;
    display: block;
    width: 100%;
    height: 100%;
}
.profile-img-circle span img {
    max-width: 100%;
    height: 100%;
}
.profile-img-circle .edit-img {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 18px;
    height: 18px;
    background-color: #E94D51;
    position: absolute;
    bottom: 0;
    right: 0;
    opacity: 1;
    cursor: pointer;
    pointer-events: unset;
    font-size: 18px;
    font-weight: bold;
    line-height: 100%;
    color: #fff;
}
.profile-img-circle .edit-img input {
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    width: 18px;
    border-radius: 100%;
    z-index: 10;
}