.search-box-wrapper {
    border: 1px solid #E6E6E6;
    border-radius: 40px;
    width: 100%;
    height: auto;
}
.search-input-wrapper {
    height: 38px;
    display: flex;
    align-items: center;
    padding: 0 20px;
}
.search-box-wrapper.open {
    background-color: #fff;
    border-radius: 20px;
}
.search-box-wrapper.open .search-input-wrapper {
    border-bottom: 1px solid #E6E6E6;
}
.search-input-wrapper .search-icon {
    margin-right: 15px;
    height: 16px;
    width: 16px;
}
.search-input-wrapper .mic-wrapper {
    border-left: 1px solid rgba(143, 148, 155, 0.5);
    padding-left: 16px;
    margin-left: 16px;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.search-input-wrapper .search-input {
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: unset !important;
    outline: unset !important;
    padding: 4px 2px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #3A3A3A;
}
.suggestion-list-wrapper {
    padding: 10px 22px;
    padding-top: 0;
    max-height: 400px;
    overflow-y: auto;
}
.suggestion-list-wrapper .title {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #BDBDBD;
    margin: 0;
    padding-left: 25px;
    margin-top: 10px;
}
.suggestion-list-wrapper ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
}
.suggestion-list-wrapper ul li {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #8F949B;
    position: relative;
    padding: 5px 15px 0 25px;
    cursor: pointer;
}
.suggestion-list-wrapper ul li::before{
    content: url(https://cima-im-cdn.itnext-dev.com/images/vendor/searchGrey.png);
    position: absolute;
    left: 0;

}
.suggestion-list-wrapper ul li .dark,
.suggestion-list-wrapper ul li .DARK {
    color: #3A3A3A;
}