.navbar-employee-master .nav-padding {
    padding: 20px 10px 10px 10px;
}

.navbar-employee-master {
    height: 75px;
}

.navbar-employee-master .navbar-container {
    background: #fff;
    box-shadow: var(--header-shadow-black);
}

.reinvestigate-case-page .navbar-container {
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(235, 175, 0, 0.25);
}

.reinvestigate-cases-details-page .navbar-container {
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(235, 175, 0, 0.25);
}

.navbar-employee-master .logo-name {
    vertical-align: bottom;
    color: black;
}

.navbar-container .title {
    font-weight: 500;
}

.navbar-employee-master .message-icon {
    cursor: pointer;
}

.navbar-employee-master .bell-icon {
    cursor: pointer;
    margin-left: 20px;
}

.navbar-employee-master .search-icon {
    cursor: pointer;
    margin-left: 20px;
}

.navbar-employee-master .navbar {
    height: 100%;
    margin-bottom: 0;
    padding-top: 35px;
}

.navbar-employee-master svg {
    margin-top: 6px;
}

.navbar-employee-master .title {
    margin-left: 10px;
    font-size: var(--xl-text);
    color: #3A3A3A;
}