.onboarding-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 19px 28px 0px 28px;
}

.onboarding-body {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.onboarding-header-title {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: var(--text-20);
    line-height: 24px;
    text-align: center;
    color: var(--primary-color);
}

.onboarding-title {
    margin-top: 15px;
    height: 62px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: var(--text-26);
    line-height: 31px;
    text-align: center;
    color: var(--primary-color);
}
.onboarding-title1 {
    margin-top: 55px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: var(--text-26);
    line-height: 31px;
    text-align: center;
    color: var(--primary-color);
}
.onboarding-title2 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: var(--text-26);
    line-height: 31px;
    text-align: center;
    color: var(--primary-color);
}

.onboarding-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    width: 350px;
    height: 350px;
    top: 249px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(228, 33, 37, 0.25);
    border-radius: 350px;
}

.onboading-footer {
    margin: 45px 27px 0px 28px;
}

.onboading-footer-btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.onboarding-skip-btn {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: var(--md-text);
    line-height: 15px;
    color: #8F949B;
    width: 56px;
    height: 23px;
    left: 28px;
    top: 767px;
    background: #FFFFFF;
    border: 1px solid #8F949B;
    border-radius: 15px;
}

.onboarding-next-btn {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: var(--md-text);
    line-height: 15px;
    color: #FFFFFF;
    width: 56px;
    height: 23px;
    top: 767px;
    background: var(--primary-color);
    box-shadow: 0px 4px 20px rgba(228, 33, 37, 0.25);
    border-radius: 15px;
    border: none;
}

.onboarding-carousol-container {
    display: flex;
    justify-content: space-between;
    width: 73px;
}

.onboarding-dots {
    width: 5px;
    height: 5px;
    top: 777px;
    background: #8F949B;
    border-radius: 50px;
}

.onboarding-dots-active {
    width: 34px;
    height: 5px;
    background: var(--primary-color);
    border-radius: 40px;
}

.onboarding-imageCtrlFlex-parent {
    margin-top: 18px;
    display: flex;
    justify-content: center;
}

.onboarding-imageCtrlFlex {
    position: absolute;
    bottom: 10px;
    display: flex;
    align-items: center;
}