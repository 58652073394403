.submit-final {
    padding: 20px 12px;
    margin-bottom: 150px;
}

.submit-final span {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #3A3A3A;
}

.submitfinal-container {
    height: auto;
    margin-top: 15px;
    padding: 13px 11px;
    display: flex;
    flex-direction: column;
    border: 1px solid #6C5CE7;
    border-radius: 10px;
}

.dd .btn, .dd .show>.btn-secondary.dropdown-toggle,.dd .btn-secondary:hover{
    height: 37px;
    background: rgba(108, 92, 231, 0.1);
    width: -webkit-fill-available;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    box-shadow: none;
    text-align: start;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #3A3A3A;
}
.dd .btn-group{
    display: block;
}

.dd{
    margin: 6px 0px;
    align-items: center;
}
.submit-dropdown:last-child{
    margin-bottom: 12px;
}

.submit-dropdown .dropdown-toggle::after {
    position: sticky;
    left: 100%;
}

.submit-dropdown .dropdown-toggle::after[aria-expanded="true"] {
    transform: rotate(180deg);
}

.submit-dropdown .dd .btn, .dd .show>.btn-secondary.dropdown-toggle,.dd .btn-secondary:hover[aria-expanded="true"]{  
    color: #212529;
    border-bottom: transparent;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
} 

.submit-dropdown .dd .btn, .dd .show>.btn-secondary.dropdown-toggle,.dd .btn-secondary{
    padding: 10px;
}

.dd .label-text{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #3A3A3A;
}

.dd .dropdown-menu.show{
    width: 100%;
    transform: translate3d(0px, 37px, 0px) !important;
}
.submit-final .dropdown-toggle {
    padding-right: 30px !important;
}
.submit-final .dropdown-toggle::after {
    vertical-align: 0 !important;
    content:url('https://cima-im-cdn.itnext-dev.com/images/vendor/ddarrow.png'); 
    border-top: none;
    position: absolute;
    left: unset;
    right: 8px;
}

.generic-dropdownitems {
    white-space: normal !important;
    word-wrap: break-word;
}

.submit-dropdown .dd .btn, .dd .show>.btn-secondary.dropdown-toggle, .dd .btn-secondary{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.submit-dropdown .disabled.btn-group button.dropdown-toggle{
    background-color: #E6E6E6 !important;
}