.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    border-color: #fff #fff var(--primary-color);
    color: var(--primary-color);
}

.cardTabs {
    margin: 0 10px;
}

.nav-tabs .nav-link {
    border: 2px solid transparent;
    color: #505050;
}

.nav {
    display: flex;
    flex-wrap: nowrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.nav-link {
    width: max-content;
}

.nav-bar-textstyle {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: var(--lg-text);
    line-height: 17px;
    text-align: center;
}

.map-data {
    box-sizing: border-box;
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 0px 3px 0px 3px;
    width: 100%;
}

.map-data-breakDIV {
    width: 33.33%;
    margin-top: 15px;
    padding: 0 5px 0 5px;
}

.map-data-text1 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: var(--sm-text);
    line-height: 12px;
    word-wrap: break-word;
    color: #666666;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.map-data-text2 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    margin-top: 5px;
    font-size: var(--text-11);
    word-wrap: break-word;
    line-height: 13px;
    color: #3A3A3A;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.allotedclaim-pleasewait {
    color: #525458;
    margin-top: 10px;
    font-size: var(--md-text);
}

.alloted-claim-pleasewait {
    color: #525458;
    margin-top: 10px;
    font-size: var(--md-text);
}

.allotedclaim-maindiv {
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #058AEE;
    border-radius: 10px;
    margin-top: 10px;
    padding: 5px;
    padding-bottom: 12px;
    position: relative;
}

.investigator-maindiv {
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #058AEE;
    border-radius: 10px;
    margin-top: 5px;
    padding: 5px;
    padding-bottom: 7px;
}

.allotedclaim-data {
    box-sizing: border-box;
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
}

.allotedclaim-div-data {
    width: 100%;
}

.claimview-btn {
    background: var(--primary-color);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: transparent;
    border-radius: 10px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: var(--sm-text);
    line-height: 12px;
    color: #FFFFFF;
    Width: 64px;
    Height: 25px;
}

.claimbtn-main {
    text-align: end;
}

.allotedclaim-upbuttton-style {
    width: 20px;
    margin: 0px;
    height: 20px;
    padding: 0px;
    border: transparent;
    background-color: transparent;
}

.allotedclaim-upbuttton-img {
    width: 12px;
    height: 7px;
}

.allotedclaim-upbuttton-mainstyle {
    width: 100%;
    text-align: end;
    left: -10px;
    position: absolute;
}

.allotedclaim-upbuttton-mainstyle2 {
    width: 100%;
    text-align: end;
    padding-right: 5px;
}

.allotedclaim-map-data-breakDIV {
    width: 33.33%;
    margin-top: 7px;
    padding: 0 3px 0 3px;
}

.investigator-map-data-breakDIV {
    width: 50%;
    padding: 7px 3px 7px 3px;
}

.Investigatorblockmain-View {
    width: 33.33%;
    padding: 7px 3px 7px 3px;
}

.allotedclaim-document-title {
    margin: 0px 3px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: var(--md-text);
    color: #000000;
}

.investgation-header {
    font-style: normal;
    font-weight: 500;
    font-size: var(--lg-text);
    line-height: 17px;
    color: #3A3A3A;
    padding: 7px 0px 6px 0px;
}

.investigaorview-btn {
    background: var(--primary-color);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: transparent;
    border-radius: 10px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: var(--sm-text);
    line-height: 12px;
    color: #FFFFFF;
    Width: 97px;
    Height: 25px;
}

.assignedblocks-viewbtn-position {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.assignedblocks-viewbtn-position2 {
    text-align: end;
}

.assignedblocks-viewbtn {
    background: var(--primary-color);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: transparent;
    border-radius: 10px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: var(--sm-text);
    line-height: 12px;
    color: #FFFFFF;
    Width: 60px;
    Height: 20px;
}

.assignedblocks-mains {
    background: #FFFFFF;
    border: 1px solid #058AEE;
    box-shadow: 0px 4px 4px rgba(108, 92, 231, 0.25);
    border-radius: 10px;
    margin: 4px;
    padding: 3px 3px 10px 3px;
    min-height: 70px;
}

.assignedblocks-header {
    font-style: normal;
    font-weight: 500;
    font-size: var(--md-text);
    line-height: 15px;
    color: #3A3A3A;
    padding-bottom: 5px;
}

.assignedblocks-flexbox {
    box-sizing: border-box;
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    justify-content: center;
}

.assignedblocks-maindiv {
    width: 100%;
    padding-top: 13px;
}

.assignedblocks-subhead {
    font-style: normal;
    font-weight: 500;
    font-size: var(--sm-text);
    line-height: 12px;
    text-align: center;
    color: #3A3A3A;
    min-height: 30px;
    padding: 3px 0;
}

.assignedblockimage {
    height: 15px;
    width: 15px;
}

.assignedblockimage-position {
    text-align: end;
    font: -webkit-small-control;
}

.assignedblocks-textareamain {
    width: 100%;
    margin-top: 21px;
}

.assignedblocks-textarea {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #8F949B;
    border-radius: 10px;
    min-height: 92px;
    font-size: var(--md-text);
    padding: 10px;
    resize: none;
    -webkit-user-drag: none;
    -moz-user-drag: none;
    -ms-user-drag: none;
}

.investigationmain{
    margin-bottom: 20px;
}

.allotted-cases-details-page .cardTabs .nav-tabs {
    border-bottom: 0.5px solid #058AEE !important;
    overflow-y: hidden;
}

.allotted-cases-details-page .docs {
    margin: 0px 0px;
    width: fit-content;
    min-width: 150px;
    padding: 8px;
    display: flex;
    align-items: center;
    background: #FAFAFA;
    border: 1px solid #058AEE;
    border-radius: 4px;
}

.allotted-cases-details-page .docs-right{
    margin-left: 4px;
}

.alloteddispatch-card{
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border: 1px solid #058AEE;
    background: #FFFFFF;
    border-radius: 10px;
}

.assignedblocks-block-width{
    width: 31%;
}

@media screen and (max-width: 375px) {
    .assignedblocks-block-width{
        width: 30%;
    }
  }

.Claimdocument-title {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: var(--md-text);
    color: #000000;
}

.claimdcname{
    font-size: 10px;
}
.allottedblocks-mains {
    border: 1px solid #058AEE;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(5, 138, 238, 0.25);
    border-radius: 10px;
    margin: 4px;
    padding: 3px 3px 10px 3px;
    min-height: 70px;
}
.assignedInvestigators {
    width: 100%;
}
.assignedInvestigators .accordion-button {
    padding: 10px;
}
.allotedclaim-data .assignedInvestigators .accordion-button.collapsed {
    border-radius: 10px;
    border: unset;
    background: linear-gradient(135deg, rgba(27, 161, 161, 0.30) 0%, rgba(0, 132, 255, 0.30) 100%);
    box-shadow: 0px 4px 5px 0px rgba(5, 138, 238, 0.50);
    color: #3A3A3A;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.assignedInvestigators .accordion-item{
    border-radius: 10px;
}
.assignedInvestigators .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}
.assignedInvestigators .accordion-button:not(.collapsed) {
    background: #FFF;
    color: #3A3A3A;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 10px;
    box-shadow: none;
    padding: 10px 10px 5px 10px;
    z-index: unset;
}
.allotedclaim-data .assignedInvestigators .accordion-item{
    border: 1px solid #058AEE;
    background: #FFF;
}
.allotedclaim-data .assignedInvestigators .accordion-button:not(.collapsed)::after{
    background-image:url("https://cima-im-cdn.itnext-dev.com/images/vendor/allotted_not_collapsed.png") !important;
    transform: rotate(360deg);
    width: 17px;
    height: 9px;
}
.allotedclaim-upbuttton-imgreinv{
    width: 17px;
    height: 9px;
}
.assignedInvestigators{
    margin-top: 22px;
}
.assignedInvestigators .accordion-body .toggle img{
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform .2s ease-in-out;
    float: right;
}
.allotedclaim-data .assignedInvestigators .accordion-body .toggle {
    border: none;
    background: unset;
    outline: unset;
    margin: 0;
    padding-bottom: 10px;
    margin-left: auto;
    display: block;
    padding-right: 0;
}
.allotedclaim-data .assignedInvestigators.Caseblocks .accordion-body .toggle {
    padding-right: 10px;
    padding-top: 10px;
    border: none;
    background: unset;
    outline: unset;
    margin: 0;
    padding-bottom: 0;
    margin-left: auto;
    display: block;
}
.assignedInvestigators .accordion-body .label{
    color: var(--black-color-70, #666);
    font-family: 'Inter', sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.assignedInvestigators .accordion-body .value{
    color: var(--black-color-90, #3A3A3A);
    font-family: 'Inter', sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.assignedInvestigators .accordion-body .col-4{
    margin-bottom: 15px;
}
.assignedInvestigators .accordion-body{
    padding: 10px 10px 15px 10px;
}
.Caseblocks .accordion-body{
    padding: 0px;
}
.Caseblocks .accordion-button{
    padding: 10px;
}
.allotedclaim-data .caseBlocks {
    border-radius: 10px;
    border: 1px solid #058AEE;
    background: linear-gradient(135deg, rgba(27, 161, 161, 0.30) 0%, rgba(0, 132, 255, 0.30) 100%);
    box-shadow: 0px 4px 5px 0px rgba(5, 138, 238, 0.50);
    width: 100%;
    color: var(--black-color-90, #3A3A3A);
}
.reininvestigator-maindiv2{
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 10px;
    margin-top: 5px;
    padding-bottom: 7px; 
}
.invList {
    margin: 0;
}
.allotedclaim-data .invList:not(:first-child) {
    border-top: 1px solid rgba(5, 138, 238, 0.50);
    padding-top: 15px;
}
.invList > * {
    padding: 0;
    padding-right: 15px;
}