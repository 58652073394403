.cardTabs .nav-tabs::-webkit-scrollbar {
    width: 2px;
    height: 4px;
    display: none;
  }
  
  .cardTabs .nav-tabs::-webkit-scrollbar-track {
    border-radius: 4px;
    background: #eee; 
    display: none;
  }
   
  .cardTabs .nav-tabs::-webkit-scrollbar-thumb {
    background: #ccc; 
    border-radius: 4px;
    display: none;
  }
  
  @media only screen and (min-width: 900px)  
{ 
    .cardTabs .nav-tabs::-webkit-scrollbar {
        width: 2px;
        height: 4px;
      }
      
      .cardTabs .nav-tabs::-webkit-scrollbar-track {
        border-radius: 4px;
        background: #eee; 
        display: block;
      }
       
      .cardTabs .nav-tabs::-webkit-scrollbar-thumb {
        background: #ccc; 
        border-radius: 4px;
        display: block;
      } 
}  

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    border-bottom: 3px solid var(--primary-color) !important;
    border-color: #fff #fff var(--primary-color);
    color: var(--primary-color);
    margin-bottom: -1px !important;
}

.cardTabs {
    margin: 0 10px;
}

.nav-tabs .nav-link.active {
    background-color: transparent !important;
}

.nav-tabs .nav-link {
    padding:10px 16px!important;
    border: none !important;
    color: #505050;
}

.cardTabs .nav-tabs {
    border-bottom:none;
    overflow-y: hidden;
}

.nav-tabs .nav-link:hover {
    border-color: none;
    isolation: none;
}

.nav {
    display: flex;
    flex-wrap: nowrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.nav-link {
    width: max-content;
}
.policy hr{
    border: 1px solid #0984E3;
}
.map-data {
    box-sizing: border-box;
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.map-data-breakDIV {
    width: 33.33%;
    margin-top: 15px;
    padding: 0 5px 0 5px;
}

.map-data-text1 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: var(--sm-text);
    line-height: 12px;
    word-wrap: break-word;
    color: #666666;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.map-data-text2 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    margin-top: 5px;
    font-size: var(--text-11);
    word-wrap: break-word;
    line-height: 13px;
    color: #3A3A3A;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.nav-bar-textstyle {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: var(--lg-text);
    line-height: 17px;
    text-align: center;
}

.riskgreentick-mainstyle{
    display: flex;
}

.riskgreentick-style {
    height: 15px;
    width: 15px;
}

.document-title {
    margin: 0px 10px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: var(--md-text);
    color: #000000;
}
.docs-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.docs {  
    margin: 17px 10px;
    width: fit-content;
    padding: 8px;
    display: flex;
    align-items: center;
    background: #FAFAFA;
    border: 1px solid #0984E3;
    border-radius: 4px;
}

.docs img {
    height: 16px;
}

.docs-right {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
}

.docs-name {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: var(--md-text);
    line-height: 15px;
    color: #3A3A3A;
}

.docs-size {
    text-align: left;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: var(--xs-text);
    line-height: 10px;
    color: #3A3A3A;
}

.endorsement:first-child{
    margin-top: 20px;
}

.endorsement .card{
    background: #fff;
    box-shadow: none;
    border: 1px solid #0984E3;
}
.tab-content{
    margin-bottom: 150px;
}
.open-clases-details-page .tab-content {
    margin-bottom: 150px !important;
}
