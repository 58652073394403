.mydatepicker {
    width: 115px;
    position: relative;
}

.mydatepicker .react-datepicker-wrapper {
    width: 115px;
}

.calendar-icon {
    position: absolute;
    top: 0px;
    left: 95px;
}

.status-update-input::placeholder {
    font-size: var(--md-text);
}

.status-update-input {
    max-width: 115px;
    border-radius: 5px;
    font-size: 10px;
    padding: 4px;
    border: 1px solid #8F949B;
}

.statusdatepicker .react-datepicker__input-container input::placeholder {
    font-size: 10px;
}