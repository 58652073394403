.addNewLocation-location-main {
    border-radius: 10px;
    border: 1px solid #E8E8E8;
    background: #FFF;
    padding: 10px;
    margin: 20px 0px;
}

.addNewLocation-location-heading {
    color: var(--black-color-60, #7C7C7C);
    font-size: 12px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.locationdropdown-item{
    color: #3A3A3A;
    font-size: 12px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 7px;
}

.locationDropdown .dropdown-menu.show{
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 400px;
    transform: translate3d(0px, 0px, 0px) !important;
    position: relative !important;
    inset: -2px auto auto 0px !important;
    padding: 0px;
    border-top: none;
    border-radius: 0px 0px 5px 5px;
}

.locationdropdown-item2{
    align-items: center;
    flex-direction: row;
    display: flex;
    border-radius: 5px 5px 0px 0px;  
}

.locationDropdown .dropdown-toggle::after{
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.5em solid;
    border-right: 0.4em solid transparent;
    border-bottom: 0;
    border-left: 0.4em solid transparent;
}

.Dropopenlocation .dropdown-toggle::after {
    transform: rotate(180deg);
}

.locationDropdown .checkbox-container {
    accent-color: #E94D51;
}

.crossimgwidth-addNewLocation {
    width: 12.462px;
    height: 12.542px;
    flex-shrink: 0;
}
  
.addNewLocation-details-remove{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #E42125;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background: #FFFFFF;
    padding: 5px;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    position: absolute;
    margin-bottom: 4px;
    transform: translate3d(15px, -18px, 0px);
}

.addNewLocation-close-div-main{
    justify-content: flex-end;
    display: flex;
}

.AddNewLocation-button{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: var(--lg-text);
    text-align: center;
    color: #FFFFFF;
    min-width: 119px;
    height: 42px;
    border-radius: 20px;
    line-height: 17px;
    border: none;
    outline: none;
    z-index: 1 !important;
    margin: auto;
    width: auto;
    white-space: nowrap;
}

.AddNewLocation-button-maincontainer{
    margin-top: 20px;
}


