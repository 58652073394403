.AssignBlocksFormBottom {
    margin-bottom: 125px;
    margin-bottom: 150px;
}

.AssignBlocksFormBottom hr {
    margin: 18px 12px 0px 13px;
    border: 1px solid #8F949B;
}

.AssignBlocksFormBottom-title {
    margin: 17px 17px 0px 20px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
}

.assignBlockdocs-container {
    display: flex;
    justify-content: space-between;
}

.assign-docs-parent {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 10px;
}

.assign-docs-parent2 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 15px 20px;
}

.docs-title-btn-wrapper {
    padding: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.docs-title-wrapper {
    font-size: 12px;
}

.assignBlock-upload-btn {
    border: none;
    display: flex;
    align-items: center;
    margin-right: 24px;
    margin-top: 17px;
    width: 83px;
    height: 25px;
    background: #E94D51;
    border-radius: 10px;
}

.assignBlock-upload-btn2 {
    border: none;
    display: flex;
    align-items: center;
    margin-top: 0;
    width: auto;
    height: 25px;
    background: #E94D51;
    border-radius: 10px;
    padding: 0 10px;
    cursor: pointer;
}

.assignBlock-upload-btn2 span {
    font-size: 10px;
    color: #FFFFFF;
    margin-left: 8.33px;
}

.assignBlock-upload-btn img {
    margin-left: 7px !important;
}

.assignBlock-upload-btn span {
    font-size: 10px;
    color: #FFFFFF;
    margin-left: 8.33px;
}

.AssignBlocksFormBottom textarea {
    padding-left: 10px;
    border-radius: 10px;
    width: 100%;
    height: 92px;
    outline: none;
    border: 1px solid #8F949B;
}

.AssignBlocksFormBottom-textarea {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    color: #8F949B;
    margin: 17px 15px 0 10px;
}

.AssignBlocksFormBottom-textarea textarea {
    padding: 10px;
    resize: none;
    -webkit-user-drag: none;
    -moz-user-drag: none;
    -ms-user-drag: none;
}

.rejected-cases-details-page hr {
    display: none;
}