.filter-date-container {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-gap: 8px;
    margin-right: 13px;
  }
  
.filter-date{
    width: inherit;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.filter-date-icon{
    right: 8px;
    position: absolute;
}

.filter-date input{
    width: inherit;
    height: 36px;
    border-radius: 4px;
    background: #FAFAFA;
    font-size: 12px;
    padding: 8px;
    border: none;
    outline: none;
}

.select-button {
    appearance: none;
    background-color: transparent;
    border: none;
    color: #000;
    cursor: pointer;
    font-size: 14px;
  }
  
  .select-button:focus {
    outline: none;
  }
  
  .select-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .filter-date input{
    width: 100% !important;
  }

  .filter-date .css-1xc3v61-indicatorContainer{
    padding: 0px;
  }