:root {
    --primary-color: #E42125;
    --xs-text: 8px;
    --sm-text: 10px;
    --md-text: 12px;
    --lg-text: 14px;
    --xl-text: 16px;
    --xxl-text: 18px;
    --text-9: 9px;
    --text-11: 11px;
    --text-13: 13px;
    --text-20: 20px;
    --text-22: 22px;
    --text-24: 24px;
    --text-26: 26px;
    --header-shadow-purple: 4px 0px 4px 0px #6C5CE7;
    --header-shadow-blue: 4px 0px 4px rgba(9, 132, 227, 0.25);
    --header-shadow-purplelight: 4px 0px 4px rgba(108, 92, 231, 0.25);
    --header-shadow-black: 4px 0px 4px rgba(0, 0, 0, 0.25);
    --header-shadow-pink: 4px 0px 4px rgba(152, 82, 144, 0.25);
    --header-shadow-orange:4px 0px 4px rgba(249, 127, 81, 0.25);
    --header-shadow-green:4px 0px 4px rgba(0, 184, 148, 0.25);
}
@media only screen and (min-width: 992px)  {
    :root {
        /* --xs-text: 10px;
        --sm-text: 12px;
        --md-text: 14px;
        --lg-text: 16px;
        --xl-text: 18px;
        --xxl-text: 20px;
        --text-9: 11px;
        --text-11: 13px;
        --text-13: 15px;
        --text-20: 22px;
        --text-22: 24px;
        --text-24: 26px;
        --text-26: 28px; */
        --header-shadow-purple: 0px 0px 0px 0px #6C5CE7;
        --header-shadow-blue: 0 0px 4px rgba(9, 132, 227, 0.25);
        --header-shadow-purplelight: 0px 0px 4px rgba(108, 92, 231, 0.25);
        --header-shadow-black: 0px 0px 4px rgba(0, 0, 0, 0.25);
        --header-shadow-pink: 0 0px 4px rgba(152, 82, 144, 0.25);
        --header-shadow-orange: 0 0px 4px rgba(249, 127, 81, 0.25);
        --header-shadow-green: 0 0px 4px rgba(0, 184, 148, 0.25);
    }
}