.employementSuccess {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.employementSuccess-upper {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.employementSuccess-tick {
    max-width: 262px;
}

.employementSuccess-title {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: var(--lg-text);
    line-height: 17px;
    text-align: center;
    color: #000000;
}
.employementSuccess-btnDiv{
    z-index: 1;
    display: flex;
    flex-direction: row;
    position: fixed;
    bottom: 65px;
    width: 100%;
    justify-content: center;
}