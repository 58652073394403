.caseicons-main{
    flex-direction: row;
}

.caseicons-img1 {
    width: 16px;
    height: 16px;
    margin-right: 18px;
    cursor: pointer;
}

.caseicons-img2 {
    height: 18px;
}

.caseicons-img3 {
    margin-right: 6px;
}