.performance-report-page{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.radialprocesschart-lower-btn {
    font-size: var(--sm-text);
    border: transparent;
    background-color: var(--primary-color);
    border-radius: 12px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: #FFFFFF;
    font-weight: 500;
    padding: 3px 12px;
}

.employee-viewbtn {
    text-align: end;
}

.employee-dispdata {
    display: flex;
    align-items: center;
    margin-top: 5px;
}

.employee-dispdata2 {
    width: 10px;
    height: 10px;
    margin-right: 5px;
    border-radius: 2px;
}

.employee-dispdata3 {
    color: #3A3A3A;
    font-size: var(--sm-text);
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    font-style: normal;
}

.performance-report-page-title {
    margin-top: 50px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #E94D51;
}

.performance-report-page .main3-employee {
    margin-bottom: 0px;
}

.performance-report-page .main2-employee {
    margin: 20px 0px 0px 0px;
}