
.coming-soon-popup-parent {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
  }
  
  .coming-soon-popup {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh;
    position: relative;
    width: 320px;
    padding: 20px;
    background-color: #fff;
    border-radius: 5px;
    text-align: center;
  }
.coming-soon-popup-close{
    cursor: pointer;
    position: absolute;
    top: 5%;
    right:5%;
}