.EmpMasterBtn{
font-family: 'Inter', sans-serif;
font-style: normal;
font-weight: 600;
font-size: var(--lg-text);
text-align: center;
color: #FFFFFF;
width: 119px;
height: 42px;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 20px;
line-height: 17px;
border: none;
outline: none;
z-index: 1 !important;
}