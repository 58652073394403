.externalHeader-style {
    color: var(--black-color-100, #242424);
    font-size: 13px;
    font-family: 'Inter', sans-serif;;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 5px;
}

.externalMainView {
    margin-top: 15px;
    border-radius: 10px;
    align-self: center;
    border: 1px solid #1AB275;
    background: #FFF;
    width: 100%;
    padding: 5px;
}

.externalMainView2 {
    flex-direction: row;
    display: flex;
}

.phcar-external-img {
    display: flex;
    width: 35px;
    height: 35px;
    padding: 8px 8px 7px 7px;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    background: rgba(62, 155, 103, 0.24);
}

.externalimg-view1 {
    display: flex;
    align-items: center;
    flex: 1;
}

.externalimg-view2 {
    align-self: center;
    flex: 1;
}

.la_id-card-solid-external-img {
    display: flex;
    width: 35px;
    height: 35px;
    padding: 8px 8px 7px 7px;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    background: rgba(57, 106, 242, 0.24);
}

.challansmaintext {
    color: #3A3A3A;
    margin-top: 15px;
    font-size: 14px;
    font-family: 'Inter', sans-serif;;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.chanllandetails-divison{
    display: flex;
    margin : 8px 0px 8px 0px;
}

.chanllandetails-divison-text1 {
    flex: 1;
    color: var(--black-color-70, #666);
    font-size: 11px;
    font-family: 'Inter', sans-serif;;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.chanllandetails-divison-text2 {
    flex: 1;
    color: var(--black-color-90, #3A3A3A);
    font-size: 12px;
    font-family: 'Inter', sans-serif;;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.chanllandetails-divison-mainheading{
    flex:0.95;
    color: #FFF;
    font-size: 12px;
    font-family: 'Inter', sans-serif;;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    align-self: center;
}

.chanllandetails-divison-mainheading2 {
    border-radius: 4px;
    display: flex;
    border: 1px solid #E8E8E8;
    background: linear-gradient(180deg, #E42125 0%, #E94D51 100%);
    padding: 3px 8px;
    margin-top: 10px;
}

.chanllandetails-divison-mainheading4{
    border-radius: 4px;
    display: flex;
    border: 1px solid #E8E8E8;
    background: linear-gradient(180deg, #156A3A 0%, #3C9563 100%);
    padding: 3px 8px;
    margin-top: 10px; 
}

.chanllandetails-divison-mainheading5 {
    display: flex;
    border-radius: 4px;
    border: 1px solid #E8E8E8;
    background: white;
    padding: 3px 8px;
    margin-top: 10px;
}

.chanllandetails-divison-main{
    border-radius: 4px;
    border: 1px solid #E8E8E8;
    background: #FFF;
    padding: 0px 8px;
    margin-top: 5px;
}

.chanllandetails-upbuttton-img {
    width: 14px;
    height: 14px;
    flex-shrink: 0;
}

.chanllandetails-divison-mainheading3{
    flex:0.05;  
}

.chanllandetails-divison-mainheading11{
    flex: 0.95;
    color: var(--black-color-90, #3A3A3A);
    font-size: 12px;
    font-family: 'Inter', sans-serif;;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    align-self: center;
}