.removeBtnDiv{
    display: flex;
    flex-direction: row-reverse;
}

.removeBtnDiv > .removeBtn {
    border: 1px solid #BCBCBC;
    background-color: #FFFFFF;
    border-radius: 20px;
    display: flex;
    padding: 5px 10px;
}

.removeBtn > .removeBtnIcon {
    width: 15px;
    height: 15px;
    margin: 0 5px;
}

.removeBtnText {
    font-size: 10px;
    font-weight: 600;
    color: #656565;
    margin-right: 5px;
}